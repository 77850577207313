import { configureStore } from '@reduxjs/toolkit'
import { businessTravelApi } from '../features/businessTravelApi'
import { setupListeners } from '@reduxjs/toolkit/query/react'

export const store = configureStore({
    reducer: {
        [businessTravelApi.reducerPath]: businessTravelApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(businessTravelApi.middleware),
});

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
