import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IErrorActionDialog from "../../../../interfaces/IErrorActionDialog";

const ErrorActionDialog : React.FC<IErrorActionDialog> = (iErrorActionDialog: IErrorActionDialog): JSX.Element => {
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
        iErrorActionDialog.onCloseErrorDialog();
    };

    React.useEffect(() => {
    }, [open]);

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Error – Date(s) Entered Already Exists</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText
                        id="scroll-dialog-description"
                        tabIndex={-1}
                    >A date(s) within this trip is already accounted for within a previous trip(s).
                            Please correct the date in either the validated or unvalidated trips screen.
                            If the trip has already been processed in a previous BTVT monthly cycle,
                            please submit a Service Gateway ticket. If payroll withholdings have already
                        been completed in Workday, the trip cannot be corrected.<br/><br/>
                        Please see the FAQs in the BTVT for more information...<br/><br/>
                            The day(s) already entered are: <br/><br/>{
                            iErrorActionDialog.excludedTrips.join(', ')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ErrorActionDialog;
