import { Card, LoadingIndicator, Typography, MessageBlock } from "cfa-react-components";
import {
    useReactTable,
    createColumnHelper,
    getCoreRowModel,
    SortDirection,
    SortingState,
    getSortedRowModel,
    flexRender,
} from "@tanstack/react-table";
import styles from "./LocationsTable.module.scss"
import LocationDays from "../../../interfaces/LocationDays";
import React, {useEffect, useState} from "react";

export interface LocationsTable {
    type: "domestic" | "international"
    data: LocationDays[]
    isLoading: boolean
    isError: boolean
}

const columnHelper = createColumnHelper<LocationDays>();

const defaultColumns = [
    // @ts-ignore
    columnHelper.accessor('locationKey', {
        header: () => <Typography>Location</Typography>,
        footer: info=> info.column.id
    }),
    columnHelper.accessor('unvalidatedDays', {
        header: () => <Typography>Unvalidated Working Days</Typography>,
        footer: info => info.column.id,
        enableSorting: false
    }),
    columnHelper.accessor('validatedDays', {
        header: () => <Typography>Validated Working Days</Typography>,
        footer: info=> info.column.id,
        enableSorting: false
    }),
    columnHelper.accessor('processedDays', {
        header: () => <Typography>Processed Working Days</Typography>,
        footer:info=> info.column.id,
        enableSorting: false
    }),
    columnHelper.accessor('totalDays', {
        header: () => <Typography>Total Working Days</Typography>,
        footer: info=> info.column.id,
        enableSorting: false
    }),
    columnHelper.accessor('stateThreshold', {
        header: () => <Typography>State Threshold</Typography>,
        cell: i => i.getValue() == -1 ? "N/A" : i.getValue(),
        footer: info=> info.column.id,
        enableSorting: false
    })
];

const LocationsTable: React.FC<LocationsTable> = ({type, data, isLoading, isError}): JSX.Element => {
    const [columns] = useState([...defaultColumns])
    const [columnVisibility, setColumnVisibility] = useState({stateThreshold: type !== 'international', })
    const initialSorting: SortingState = [{
        id: 'locationKey',
        desc: false
      }];
    const [sorting, setSorting] = useState<SortingState>(initialSorting)
    // @ts-ignore
    const table = useReactTable({data, columns, state: { sorting, columnVisibility }, onColumnVisibilityChange: setColumnVisibility, getCoreRowModel: getCoreRowModel(), getSortedRowModel: getSortedRowModel(), onSortingChange: setSorting, enableSortingRemoval: false})
    useEffect(() => {
        setColumnVisibility(prevState => ({
            ...prevState,
            stateThreshold: type !== 'international',
        }))
    }, [type]);
    return(
        isLoading? <Card className={styles.tableContainer}>
            <LoadingIndicator size="md" variant="inline"></LoadingIndicator>
        </Card>
        : isError? <Card className={styles.tableContainer}>
            <MessageBlock severity="error" title="Dashboard Error">Trip location data could not be found. Please try refreshing.</MessageBlock>
        </Card> :
                <Card className={styles.tableContainer}>
                    <Typography className={styles.title} variant={"subtitle3"}>{type === "domestic" ? "US Domestic Trips" : "International and US Territories Trips"}</Typography>

                    <table>
                    <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <th key={header.id} colSpan={header.colSpan}>
                                    {header.isPlaceholder ? null : (
                                        <div 
                                        className={
                                            header.column.getCanSort() ? 'cursor-pointer select-none'
                                            : ''
                                        }
                                        onClick={header.column.getToggleSortingHandler()}
                                        title={
                                            header.column.getCanSort() 
                                                ? header.column.getNextSortingOrder() === 'asc'
                                                    ? 'Sort ascending'
                                                    : header.column.getNextSortingOrder() === 'desc'
                                                        ? 'Sort descending'
                                                        : 'Clear Sort'
                                                    : undefined
                                        }
                                    >
                                        <div className={styles.headerGroup}>
                                            {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                                {{
                                                asc: <div className={styles.sortArrow}>⬆</div>,
                                                desc: <div className={styles.sortArrow}>⬇</div>,
                                            }[header.column.getIsSorted() as SortDirection] ?? null}
                                        </div>
                                    </div>
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody>
                    {table.getRowModel().rows.map(row => (
                        <tr key={row.id}>
                            {row.getVisibleCells().map(cell => (
                                <td key={cell.id} className={styles.locationTableDataCell}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </Card>
    )
}
export default LocationsTable;