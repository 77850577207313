import React, {useEffect, useState} from "react";
import {
    CircularProgress,
    Grid,
    TextField
} from "@material-ui/core";
import './delegate.scss';
import {Autocomplete} from "@material-ui/lab";
import I8ln from "../../../i18n/utils";
import SaveActionDialog from "./SaveActionDialog/SaveActionDialog";
import { Person } from "@cfacorp/business-travel-api-client";
import DelegateDisclaimerDialog from "./DelegateDisclaimerDialog/DelegateDisclaimerDialog"
import {getFullName} from "../../utils/helpers/PersonHelper";
import { useGetPeopleQuery } from "../../redux/features/businessTravelApi";
import { useGetMasqueradeSessionQuery } from "../../redux/features/masqueradeApi";
import { useGetUserConfigurationQuery } from "../../redux/features/delegatesApi";

const Delegate: React.FC = (): JSX.Element => {
    /**** Autocomplete related attributes ****/
    const [open, setOpen] = useState(false);
    const [textLabel, setTextLabel] = useState<string>('Choose a delegate');
    const [isUpdatable, setIsUpdatable] = useState<boolean>(false);
    /******************** ***********************/
    const [currentDelegates, setCurrentDelegates] = useState<Person[]>([]);
    const [selectedDelegates, setSelectedDelegates] = useState<Person[]>([]);

    const { data: masqueradedPerson } = useGetMasqueradeSessionQuery(); 

    const [errorMessage, setErrorMessage] = useState<string>('');

    const {data: options, isError: isPeopleError} = useGetPeopleQuery();

    //get user configuration, find person object in options list, should only run once when page is loaded and we don't know delegate person
    const { data: configuration, isError: isConfigurationError } = useGetUserConfigurationQuery();
    const [areCurrentDelegatesLoaded, setAreCurrentDelegatesLoaded] = useState<boolean>(false)
    useEffect(() => {
        if(configuration && options && options.length > 0 ){
            if(configuration.delegateCfaId){
                const idx = options.findIndex((person) => { return person.cfaId === configuration.delegateCfaId});
                if(idx >= 0) {
                    setCurrentDelegates([options[idx]]);
                    setSelectedDelegates([options[idx]]);
                }
                else {
                    setErrorMessage(I8ln.t("FIND_DELEGATE_ERROR"));
                }
            } else {
                setSelectedDelegates([])
                setCurrentDelegates([])
            }
            setAreCurrentDelegatesLoaded(true);
        }

    }, [configuration, options])
    
    useEffect(() => {
        if(isPeopleError){
            setErrorMessage(I8ln.t("GET_PEOPLE_ERROR"))
        }
        else if(isConfigurationError){
            setSelectedDelegates([])
            setCurrentDelegates([])
            setErrorMessage(I8ln.t("GET_CONFIGURATION_ERROR"))
        }
        else {
            setErrorMessage("")
        }
    }, [isConfigurationError, isPeopleError])


    //determine autocomplete text label
    useEffect(() => {
        if(selectedDelegates.length === 0){
            setTextLabel('Choose a delegate');
        }
        else if(selectedDelegates[0] === currentDelegates[0]){
            setTextLabel('Current delegate');
        }
        else {
            setTextLabel('Selected delegate');
        }
    }, [selectedDelegates, currentDelegates])

    const deletePerson = () => {
        setSelectedDelegates([]);
        setIsUpdatable(true);
    };

    const addPerson = (personToAdd: any) => {
        if (personToAdd !== null) {
            setSelectedDelegates([personToAdd]);
            if(!currentDelegates.includes(personToAdd)){
                setIsUpdatable(true);
            }
            else{
                setIsUpdatable(false);
            }
        }
    };

    const refreshView = (): void => {
        setCurrentDelegates(selectedDelegates);
        setErrorMessage("");
        setIsUpdatable(false);
    }

    const revertView = (): void => {
        setSelectedDelegates(currentDelegates);
        setErrorMessage("");
        setIsUpdatable(false);
    }

    const handleFailSave = (): void => {
        setErrorMessage(I8ln.t("DELEGATE_SAVE_ERROR"));
    }

    const renderAutocomplete = () => {
        return <Grid className={'delegateSearchContainer'}>
                <Autocomplete
                    id="delegate-selector"
                    className={'delegateSearch'}
                    open={open}
                    value={selectedDelegates[0] || null}
                    onChange={(event, value, reason) => {
                        if (reason === 'select-option') {
                            addPerson(value);
                        } else {
                            deletePerson();
                        }
                    }}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    getOptionSelected={(option, value) => option?.cfaId === value?.cfaId}
                    getOptionLabel={(option) => getFullName(option) + ' (' + option?.email + ')'}
                    getOptionDisabled={(option) => option?.cfaId === configuration?.cfaId}
                    options={options || []}
                    loading={areCurrentDelegatesLoaded}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={textLabel}
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {!areCurrentDelegatesLoaded ? <CircularProgress color="inherit" size={20}/> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>
        
        }

    return (
        <Grid container direction={'column'}>
            {(areCurrentDelegatesLoaded && !masqueradedPerson)&& <DelegateDisclaimerDialog acknowledge={configuration?.acknowledge}/>}

            <Grid item xs={12} className={'instructionsText'} >
                <span>{I8ln.t('APP_DELEGATE_PICKER_INSTRUCTIONS')}</span>
            </Grid>

            {renderAutocomplete()}


            {errorMessage !== "" && <Grid item xs={12} className={'errorText'} >
                <span>{errorMessage}</span>
            </Grid>}

            {areCurrentDelegatesLoaded && <SaveActionDialog selectedDelegates={selectedDelegates} currentDelegates={currentDelegates}
                            isUpdatable={isUpdatable} savedConfiguration={configuration} refreshView={refreshView} revertView={revertView} handleFailSave={handleFailSave}/>}


        </Grid>)
};

export default Delegate;
