import { Person, TravelValidationConf as TravelValidationConfiguration } from "@cfacorp/business-travel-api-client";
import { formatDelegateUsers } from "../../components/Landing/LandingUtil";
import DelegateUser from "../../interfaces/DelegateUser";
import { businessTravelApi, businessTravelService } from "./businessTravelApi";

const delegatesApi = businessTravelApi.enhanceEndpoints({addTagTypes: ["Delegates", "Configuration"]}).injectEndpoints({
    endpoints: (builder) => ({
        getUserDelegates: builder.query<DelegateUser[], void>({
            query: () => ({func: businessTravelService.getDelegatesForContextUser, arg: undefined}),
            transformResponse: (response: Person[]) => formatDelegateUsers(response),
            providesTags: ["Delegates"]
        }),
        getUserConfiguration: builder.query<TravelValidationConfiguration, void>({
            query: () => ({func: businessTravelService.getConfigurationForContextUser}),
            providesTags: ["Configuration"]
        }),
        updateUserConfiguration: builder.mutation<void, TravelValidationConfiguration>({
            query: (configuration: TravelValidationConfiguration) => ({
                func: businessTravelService.saveUserConfiguration, 
                arg: { requestBody: configuration }
            }),
            invalidatesTags: ["Configuration"]
        }),
        updateAckTrue: builder.mutation<void, void>({
            query: () => ({func: businessTravelService.acknowledgeConfiguration, arg: {ack: true}}),
            invalidatesTags: ["Configuration"]
        })
    })
})

export const { 
    useGetUserDelegatesQuery, 
    useGetUserConfigurationQuery, 
    useUpdateUserConfigurationMutation, 
    useUpdateAckTrueMutation
} = delegatesApi;