import React, { useState } from "react";
import { BannerMessage, BannerMessageSeverity } from "cfa-react-components/dist/cjs"
import { Grid } from "@material-ui/core";
import "./Toaster.scss";
import { useGetBannerMessagesQuery } from "../../../redux/features/adminApi";


const Toaster: React.FC = (): JSX.Element => {
    const {data: messages} = useGetBannerMessagesQuery();

    const [dismissed, setDismissed] = useState({});

    const handleClose = (messageId: string) => setDismissed(prev => ({...prev, [messageId]: true}));

    return (
        <Grid className="toaster">
            {messages?.map(message => {
                if(!message.messageId) return null;
                const isVisible = !(message?.messageId in dismissed);
                return (
                <BannerMessage key={message.messageId} severity={message.severity.toLowerCase() as BannerMessageSeverity} show={isVisible} onClose={() => handleClose(message.messageId || '')}>
                    {message.message}
                </BannerMessage>
                )
            })}
        </Grid>
    )
}

export default Toaster;