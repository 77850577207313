import React, {useEffect, useState} from "react";
import {
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    TextField
} from "@material-ui/core";
import I8ln from "../../../i18n/utils";
import {Autocomplete} from "@material-ui/lab";
import "./masquerade.scss";
import {getFullName} from "../../utils/helpers/PersonHelper";
import { useGetPeopleQuery } from "../../redux/features/businessTravelApi";
import { Person } from "@cfacorp/business-travel-api-client";

const Masquerade: React.FC<{isDialogOpen?: boolean; closeMasquerade: (person: Person | null) => void;}> = (props): JSX.Element => {

    const [openDialog, setOpenDialog] = React.useState<boolean>(false);
    const [openSearch, setOpenSearch] = React.useState<boolean>(false);

    const {data: options, isSuccess: arePeopleLoaded} = useGetPeopleQuery();
    const loading = openDialog && options?.length === 0;

    const [isAcceptButtonDisabled, setIsAcceptButtonDisabled] = useState<boolean>(true);

    const [selectedMasquerade, setSelectedMasquerade] = useState<Person | null>(null);

    useEffect(() => {
        if (props.isDialogOpen) {
            setOpenDialog(true);
        } else {
            setOpenDialog(false);
        }

    }, [props.isDialogOpen]);

    const renderAutocomplete = () => {
        if (arePeopleLoaded) {
            return <Grid className={'delegateSearchContainer'}>
                <Autocomplete
                    id="delegate-selector"
                    className={'delegateSearch masqSearch'}
                    open={openSearch}
                    onChange={(event, value, reason) => {
                        if (reason === 'select-option') {
                            setSelectedMasquerade(value)
                            setIsAcceptButtonDisabled(false);
                        } else {
                            setSelectedMasquerade(null);
                            setIsAcceptButtonDisabled(true);
                        }
                    }}
                    onOpen={() => {
                        setOpenSearch(true);
                    }}
                    onClose={() => {
                        setOpenSearch(false);
                    }}
                    getOptionSelected={(option, value) => option?.cfaId === value?.cfaId}
                    getOptionLabel={(option) => getFullName(option) + ' (' + option?.email + ')'}
                    options={options}
                    loading={loading}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={'Choose a candidate'}
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>
        }
    }

    const handleClose = (person: Person | null) => {
        setOpenDialog(false);
        props.closeMasquerade(person);
    };

    return (<Dialog open={openDialog}>
        <DialogTitle className="masqueradeTitle">{I8ln.t('APP_MASQUERADE')}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                <span>{I8ln.t('APP_MASQUERADE_DIALOG_TEXT')}</span>
            </DialogContentText>
            {renderAutocomplete()}
        </DialogContent>
        <DialogActions>
            <Button className="closeButton" onClick={() => handleClose(null)} autoFocus>
                {I8ln.t('APP_MASQUERADE_DIALOG_CANCEL')}
            </Button>
            <Button disabled={isAcceptButtonDisabled} className="acceptButton" data-test="accept-masq" onClick={() =>
                handleClose(selectedMasquerade)} autoFocus>
                {I8ln.t('APP_MASQUERADE_DIALOG_ACCEPT')}
            </Button>
        </DialogActions>
    </Dialog>);

}

export default Masquerade;
