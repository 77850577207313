import React, {useEffect} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from "@material-ui/core";
import I8ln from "../../../../i18n/utils";
import { useUpdateAckTrueMutation } from "../../../redux/features/delegatesApi";

const DelegateDisclaimerDialog: React.FC<{acknowledge?: boolean}> = (props): JSX.Element => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [setAckTrue] = useUpdateAckTrueMutation();

    useEffect(() => {
        if(props.acknowledge){ 
            setOpen(false);
            } else { 
            setOpen(true);
        }
    }, [props.acknowledge]);

    const handleClose = () => {
        setOpen(false);
        setAckTrue();
    };

    return (
        <Dialog open={open}>
            <DialogTitle>{I8ln.t('APP_DELEGATE_DISCLAIMER_TITLE')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <span>{I8ln.t('APP_DELEGATE_DISCLAIMER')}</span>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className="acceptButton" onClick={handleClose} autoFocus data-test="accept-discalimer">
                    {I8ln.t('APP_DELEGATE_DISCLAIMER_ACCEPT')}
                </Button>
            </DialogActions>
        </Dialog>
    )

}

export default DelegateDisclaimerDialog;