import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid} from "@material-ui/core";
import React, {useState} from "react";

import IDelegateSaveActionDialog from "../../../interfaces/IDelegateSaveActionDialog";
import { getFullName } from "../../../utils/helpers/PersonHelper";
import { TravelValidationConf as TravelValidationConfiguration } from "@cfacorp/business-travel-api-client";
import { useUpdateUserConfigurationMutation } from "../../../redux/features/delegatesApi";

const SaveActionDialog : React.FC<IDelegateSaveActionDialog> = (iDelegateSaveActionDialog: IDelegateSaveActionDialog): JSX.Element => {
    const [open, setOpen] = useState(false);
    const [updateUserConfiguration] = useUpdateUserConfigurationMutation();

    let currentName: string = getFullName(iDelegateSaveActionDialog.currentDelegates[0]);
    let selectedName: string = getFullName(iDelegateSaveActionDialog.selectedDelegates[0]);

    let isRemoveCurrentDelegate: boolean = iDelegateSaveActionDialog.currentDelegates.length === 1 &&
        iDelegateSaveActionDialog.selectedDelegates.length === 0;


    const handleClickOpen = () => {
        determineSaveText();
        setOpen(true);
    };

    const handleFailClose = (): void => {
        iDelegateSaveActionDialog.handleFailSave();
        setOpen(false);
    };

    const handleDisagreeClose = () => {
        iDelegateSaveActionDialog.revertView();
        setOpen(false);
    }

    const handleAgreeClose = (config: TravelValidationConfiguration) => {
        iDelegateSaveActionDialog.refreshView(config);
        setOpen(false);
    }

    /*
        There are two scenarios, in conditional order, that occur for updating a user's delegate(s)
        1) Remove my current delegate so that I no longer have an assigned delegate.
        3) Assign a delegate or change selected delegate.
     */
    const updateDelegateConfig = () => {
        if(!iDelegateSaveActionDialog.savedConfiguration){
            handleFailClose();
            return;
        }
        
        // make a shallow copy
        let newConfig = {...iDelegateSaveActionDialog.savedConfiguration};
        
        if (isRemoveCurrentDelegate) {
            newConfig.delegateCfaId = "";
            newConfig.delegatePersonId = "";

        } else {
            newConfig.delegateCfaId = iDelegateSaveActionDialog.selectedDelegates[0].cfaId;
            newConfig.delegatePersonId = iDelegateSaveActionDialog.selectedDelegates[0].personId;
        }

        updateUserConfiguration(newConfig).unwrap()
            .then(() => {
                handleAgreeClose(newConfig);
            })
            .catch(() => {
                handleFailClose();
            });
        
    }

    const determineSaveTitle = () => {
        if (isRemoveCurrentDelegate) {
            return 'Are you sure that you want to remove the following delegate?';
        } else if (iDelegateSaveActionDialog.currentDelegates.length === 1) {
            return 'Are you sure that you want to change to the following delegate?'
        } else {
            return 'Are you sure that you want to select the following delegate?';
        }
    }

    const determineSaveText = () => {
        if (isRemoveCurrentDelegate) {
            return currentName;
        } else if (iDelegateSaveActionDialog.currentDelegates.length === 1) {
            return currentName + ' -> ' + selectedName;
        } else {
            return selectedName;
        }
    }

    return (
      <Grid className={"saveButtonContainer"}>
        <Button
          className={"saveButton"}
          disabled={!iDelegateSaveActionDialog.isUpdatable}
          onClick={() => handleClickOpen()}
          data-test="save-delegate-button"
        >
          Save
        </Button>
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {determineSaveTitle()}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {determineSaveText()}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDisagreeClose} className="acceptButton" data-test="disagree">
              Disagree
            </Button>
            <Button
              className="acceptButton"
              onClick={updateDelegateConfig}
              autoFocus
              data-test="agree"
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
}

export default SaveActionDialog;
