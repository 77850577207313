const i18nVariables = {
  // APP
  APP_NAME: process.env.REACT_APP_NAME,
  APP_SHORT_NAME: process.env.REACT_APP_NAME,
  APP_SIGN_IN: 'Sign in',

  // <b></b> tags parse-able with the parse() plugin from react-html-parser

  APP_UNAUTHORIZED_MESSAGE: 'Unauthorized to view this Application',

  // Nav Menu
  APP_SHORT_TITLE: process.env.REACT_APP_NAME,

  // Landing View
  APP_VALIDATION_INSTRUCTIONS: 'Please select an unvalidated trip below to validate your travel',
  APP_SHOW_VALIDATED_TRIPS: 'You can view the trips you have already validated or that have been processed by clicking either button below',
  APP_VALIDATED_TRIPS_BUTTON_TEXT: 'View Validated Trips',
  APP_VALIDATED_INSTRUCTIONS: 'Select a trip to review your validated trips',
  APP_PROCESSED_INSTRUCTIONS: 'Select a trip to review your processed trips',
  APP_TAX_YEAR_INSTRUCTIONS: 'Select a tax year to view your trips',
  APP_SHOW_NONVALIDATED_TRIPS: 'You can view the trips you have not validated by clicking the button below',
  APP_NONVALIDATED_TRIPS_BUTTON_TEXT: 'View Unvalidated Trips',
  APP_PROCCESSED_TRIPS_BUTTON_TEXT: 'View Processed Trips',
  APP_NO_TRIPS: 'There are no trips to validate at this time',
  APP_LOADING_MESSAGE: 'Attempting to load trip information',
  APP_VIEW_ALL_TRIPS: 'View All Trips',
  APP_DELEGATE_INSTRUCTIONS: 'Please use the dropdown to filter trips per user',
  GET_TRIPS_ERROR: "Could not retrieve trips.",
  POST_TRIPS_ERROR: "Could not submit trip data at this time",
  APP_BACK_TAX_LIST: "Back to Tax Year List",
  APP_FOR_TAX_YEAR: " for Tax Year %{taxYear}",
  APP_DATA_LOADED: "Data loaded through %{date}",
  PRIVACY_NOTICE: "Privacy notice",

  // Tax Year View
  TAX_YEAR: "Tax Year %{year}",
  TAX_RANGE: "November %{firstYear} - October %{secondYear}",

  // Day List View
  APP_DAY_LIST_PROCESSED_INSTRUCTIONS: 'This trip was available for validation in a previous period and has been sent to our vendor.',
  APP_DAY_LIST_INSTRUCTIONS: 'Please ensure the following information for each day is correct.',
  APP_DAY_LIST_VALIDATION: 'Were you working on the following days?',
  APP_DAY_LIST_PROCESSED_EDIT_INSTRUCTIONS: 'If the trip has already been processed in a previous BTVT monthly cycle, please submit a Service Gateway ticket. ' +
      'If payroll withholdings have already been completed in Workday, the trip cannot be corrected.',
  APP_DAY_LIST_SUBMIT_INSTRUCTIONS: 'Click \'Submit\' to save your changes and move to the next trip requiring validation',
  APP_DAY_LIST_START_DATE_LABEL: 'Start Date',
  APP_DAY_LIST_END_DATE_LABEL: 'End Date',
  APP_DAY_LIST_YES_CHOICE: 'Yes',
  APP_DAY_LIST_NO_CHOICE: 'No',
  APP_DAY_LIST_CANCEL_BUTTON_LABEL: 'Cancel',
  APP_DAY_LIST_SUBMIT_BUTTON_LABEL: 'Submit',
  APP_DAY_LIST_BACK_BUTTON_LABEL: 'Back',
  APP_DAY_LIST_CREATE_BUTTON_LABEL: 'Create',

  //Non-Work day reasons
  NON_WORK_REASON_CONFERENCE: 'Conference',
  NON_WORK_REASON_PTO: 'PTO or Holiday',
  NON_WORK_REASON_CANCELLED: 'Trip was cancelled',
  NON_WORK_REASON_RESCHEDULED: 'Trip was rescheduled',
  NON_WORK_REASON_WEEKEND: 'Weekend',
  NON_WORK_REASON_TRAVEL: 'Travel day',

  // Trip Creation View
  APP_TRIP_CREATION_INSTRUCTIONS: 'Create a trip that was not recorded through Direct Travel',
  APP_TRIP_CREATION_SUBMIT_INSTRUCTIONS: 'Click \'Create\' to add your newly created trip into the BTVT system',
  APP_TRIP_CREATION_STEP_ONE: 'Please select whom this trip is for:',
  APP_TRIP_CREATION_STEP_TWO: 'Please select the start date for your trip',
  APP_TRIP_CREATION_STEP_THREE: 'Please select the end date for your trip',
  APP_DAY_TRIP_CREATION_START_DATE_ERROR: 'The start date cannot be greater than the end date',
  REASON_SELECT: 'Please select a reason for creating this trip',
  REASON_PERSONAL_CAR: 'Personal Car Trip',
  REASON_VOLUNTARY_TRAVEL: 'Voluntary Travel',
  REASON_VOLUNTARY_RELOCATION: 'Voluntary Relocation',
  REASON_FORGOT_DT: 'Forgot to use Direct Travel... Will use next time',
  TRIP_CREATION_MODAL_NAME: 'Trip Creation for %{name}',
  TRIP_CREATION_MODAL_NO_NAME: 'Trip Creation',

  // Delegate View
  APP_DELEGATE_PICKER_INSTRUCTIONS: 'Select, change, or remove your delegate in the field below.',
  APP_DELEGATE_DISCLAIMER_TITLE: 'Disclaimer',
  APP_DELEGATE_DISCLAIMER: 'The travel information being validated within the BTVT will be used to determine ' +
      'if Chick-fil-A, Inc., your employer, will withhold payroll taxes for states in which you traveled for work. ' +
      'By selecting a delegate to approve, edit and adjust your travel via the BTVT app, ' +
      'you acknowledge that the information provided in this tool may ultimately impact your payroll ' +
      'tax withholdings. While you will be able to continue to monitor and make adjustments in the ' +
      'respective calendar in addition to a delegate\'s access, once the validation period has closed, ' +
      'this tool cannot be used to correct any error that you or a delegate has made without administrator ' +
      'action. Please ensure you are prudent in your selection of a delegate, and also that you ' +
      'affirmatively deauthorize (within the BTVT) a delegate who is no longer appropriate, ' +
      'for example, due to no longer having access or knowledge of your work travel schedule. ' +
      'Upon assignment, all delegates will be required to review the instructional materials ' +
      'provided to them by Benefits and Payroll Solutions. Chick-fil-A, Inc. does not provide ' +
      'financial or tax advice to employees, and none is being provided in the BTVT.',
  APP_DELEGATE_DISCLAIMER_ACCEPT: 'Accept',
  GET_CONFIGURATION_ERROR: 'Could not retrieve configuration',
  GET_DELEGATES_ERROR: 'Could not retrieve delegate information',
  FIND_DELEGATE_ERROR: 'Cannot find current delegate in staff.',
  DELEGATE_SAVE_ERROR: 'Could not save delegate configuration. Please try again.',
  GET_PEOPLE_ERROR: 'Could not retrieve list of current staff.',

  //Logo Alt Text
  APP_LOGO_ALT_TEXT: 'Company Logo',

  APP_MASQUERADE: 'Masquerade',
  APP_MASQUERADE_DIALOG_TEXT: 'Select who you would like to masquerade as:',
  APP_MASQUERADE_DIALOG_ACCEPT: "Accept",
  APP_MASQUERADE_DIALOG_CANCEL: "Cancel",
  APP_CURRENT_MASQUERADE: 'Masqueraded: ',
  APP_MASQUERADE_ACTIVATE: 'Masquerade as a user',
  APP_MASQUERADE_DEACTIVATE: 'Return to primary user',


  //admin
  INITIATE_REFRESH_ERROR: 'Error initiating trips refresh in SFTP',
  INITIATE_EXPORT_ERROR: 'Error initiating trips export in SFTP',
  SFTP_MANUAL_TITLE: 'SFTP Controls',
  SFTP_MANUAL_SUBTITLE: 'Manually Trigger SFTP Trips Ingest or Export',
  IMPORT_BUTTON: 'Refresh Trips in SFTP',
  EXPORT_BUTTON: 'Export Trips to Deloitte',
  TRIP_IMPORT_TAB: 'Trip Import/Export',
  APP_BANNERS_TAB: 'Application Banner Messages',
  BANNERS_SUBTITLE: 'Configure important messages to be displayed to all Thirteen93 users.',
  NEW_BANNER_BUTTON: 'New banner message',
  GET_BANNERS_ERROR: 'Could not get banner messages',
  DELETE_BANNER_ERROR: 'Could not delete banner message. Please try again.',
  SAVE_BANNER_ERROR: 'Could not save banner message. Please try again.',
  DELETE_MODAL_HELPER: 'Are you sure you want to delete this banner message?',
  DELETE_BUTTON: 'Delete Banner',
  SAVE_BUTTON: 'Save Banner',
  CREATE_BUTTON: 'Create Banner',
  MODAL_DEFAULT_TEXT: 'Enter message here...',

  //dashboard
  
}

export default i18nVariables;
