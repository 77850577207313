import { Grid } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import I18n from '../../../i18n/utils';
import Trip from '../../interfaces/Trip';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import LandingTripList from '../LandingTripList/LandingTripList';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import TripDaysList from '../Trip/TripDaysList/TripDaysList';
import './landing.scss';
import { filterTrips, sortTrips } from './LandingUtil';
import {TRIP_STATUS_OPEN} from "../../../constants";
import { useGetTripDaysQuery, usePostTripDaysMutation } from '../../redux/features/tripsApi';
import { TripDay } from '@cfacorp/business-travel-api-client';
import { useGetUserDelegatesQuery } from '../../redux/features/delegatesApi';
import Footer from "../Footer/Footer";

const Landing: React.FC = (): JSX.Element => {
    const { data: trips, isLoading: isTripsLoading, isError: isTripsError } = useGetTripDaysQuery()
    const [submitTrip] = usePostTripDaysMutation();

    const [selectedPersonId, setSelectedPersonId] = useState<string>('');
    const [selectedTripDays, setSelectedTripDays] = useState<TripDay[]>([]);
    const [tripStatus, setTripStatus] = useState<string>(TRIP_STATUS_OPEN);
    const [taxYear, setTaxYear] = useState<string | undefined>();

    const {data: delegateUsers, isLoading: isDelegatesLoading, isError: isDelegatesError} = useGetUserDelegatesQuery();

    const loadingData = (isTripsLoading) ||  (isDelegatesLoading);

    const sortAndFilterTrips = useMemo(() => {
      return sortTrips(
        filterTrips(trips || [], tripStatus, selectedPersonId),
        "ASC"
      )
    }, [trips, tripStatus, selectedPersonId])

    
    const onCancel = (): void => {
        setSelectedTripDays([]);
    }

    const onSubmitTrips = (tripDays: TripDay[]) => {
        return submitTrip({tripDays}).unwrap().then(() => setSelectedTripDays([]));
    }

    const updateTripView = (tripStatus: string): void => {
        setTripStatus(tripStatus);
    }

    const updateSelectedUser = (id: string): void => {
        if (id === selectedPersonId) {
            setSelectedPersonId('');
        }else {
            setSelectedPersonId(id);
        }
    }

    const getTripDays = (selectedTripDay: string): void => {
        const selectedTrip: Trip | undefined = trips?.find(f => f.tripId === selectedTripDay)

        if(selectedTrip) {
            setSelectedTripDays(selectedTrip.tripDays)
        }
    }

    return (
      <Grid container className={"landingCard"}>
        {loadingData ? (
          <LoadingSpinner loadingMessage={I18n.t("APP_LOADING_MESSAGE")} />
        ) : isTripsError ? (
          <ErrorMessage message={I18n.t("GET_TRIPS_ERROR")} />
        ) : isDelegatesError ? (
          <ErrorMessage message={I18n.t("GET_DELEGATES_ERROR")} />
        ) : !selectedTripDays.length && trips ? (
          
            <LandingTripList
              trips={sortAndFilterTrips}
              delegateUsers={delegateUsers || []}
              tripStatus={tripStatus}
              selectedUser={selectedPersonId}
              viewOnClick={updateTripView}
              selectUser={updateSelectedUser}
              tripOnClick={getTripDays}
              taxYear={taxYear}
              setTaxYear={setTaxYear}
            />
          )
         : (
          <TripDaysList
            tripDays={selectedTripDays}
            tripStatus={tripStatus}
            tripDateRange={{
              startDate: selectedTripDays[0].date,
              endDate: selectedTripDays[selectedTripDays.length - 1].date,
            }}
            onSubmit={onSubmitTrips}
            onClick={onCancel}
            isCreateManualTrips={false}
          />
        )}
          <Footer/>
      </Grid>
    );
}

export default Landing;
