import { Button, Grid, Modal } from '@material-ui/core';
import React from 'react';
import ISideMenu from '../../interfaces/ISideMenu';
import './sideMenu.scss';
import { useHistory } from 'react-router-dom';
import { isAdmin } from '../../utils/helpers/AuthHelper';
import { useOktaAuth } from "@okta/okta-react";

const SideMenu: React.FC<ISideMenu> = ({open, setOpen}: ISideMenu): JSX.Element => {
    const history = useHistory();
    const delegateRoute = () => {setOpen(false); history.push('/delegate')};
    const tripsRoute = () => {setOpen(false); history.push('/');}
    const tripCreateRoute = () => {setOpen(false); history.push('/trip/create');}
    const dashboardRoute = () => {setOpen(false); history.push('/dashboard')}
    const adminRoute = () => {setOpen(false); history.push('/admin');}
    const {authState} = useOktaAuth();
    const admin: boolean = authState?.isAuthenticated && isAdmin();

    return(
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            className={'modal'}
        >
            <Grid container className={'menuContainer'}>
                <Grid container className={'linkContainer'}>
                    <Grid item xs={12}>
                        <Button className={'button'} onClick={dashboardRoute}>View Dashboard</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button className={'button'} onClick={tripsRoute}>View Trips</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button className={'button'} onClick={tripCreateRoute}>Create Trip</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button className={'button'} onClick={delegateRoute} data-test="delegate-page-button">Delegate</Button>
                    </Grid>
                    { admin && 
                    <Grid item xs={12}>
                        <Button className={'button'} onClick={adminRoute} data-test="admin-page-button">Admin</Button>
                    </Grid>
                    }
                    <Grid item xs={12}>
                        <a className={'link'} href={'https://portal.cfahome.com/en/staff/benefits-and-pay/compensation-and-payroll/business-travel-validation-tool/'} target={'_blank'} rel={'noopener noreferrer'}>
                            <Button className={'button'}>FAQ</Button>
                        </a>
                    </Grid>
                    <Grid item xs={12}>
                        <a className={'link'} href={'https://cfaprod.service-now.com/servicegateway?id=cfastaff_sc_cat_item&sys_id=92c78de49f331200d9011977677fcf7b'} target={'_blank'} rel={'noopener noreferrer'}>
                            <Button className={'button'}>Service Gateway</Button>
                        </a>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    )
}

export default SideMenu;
