import OktaConfig from '../interfaces/OktaConfig';

const buildOktaConfig = (): OktaConfig => {
    const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID || '{clientId}';
    const ISSUER = process.env.REACT_APP_OKTA_ISSUER || 'https://cfahome.com/oauth2/default';
    const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.REACT_APP_OKTA_TESTING_DISABLEHTTPSCHECK || false;
    const REDIRECT_URI = `${window.location.origin}/implicit/callback`;

    const oktaConfig: OktaConfig = {
        oidc: {
            clientId: CLIENT_ID,
            issuer: ISSUER,
            redirectUri: REDIRECT_URI,
            scopes: ['profile', 'openid', 'email'],
            pkce: false,
            disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK
        },
        resourceServer: {
            messagesUrl: ''
        }
    }

    return oktaConfig
}

export {
    buildOktaConfig
}