import styles from "./Dashboard.module.scss"
import classNames from 'classnames';
import {
    Container,
    Dropdown,
    Card,
    Typography
  } from "cfa-react-components";
import DelegateSelect from "../DelegateSelect/DelegateSelect";
import { useGetUserDelegatesQuery } from "../../redux/features/delegatesApi";
import I18n from "../../../i18n/utils";
import React, { FC, useState, useEffect } from "react";
import LocationsTable from "./LocationsTable/LocationsTable";
import { useGetUserTravelLocations } from "../../../hooks/useGetUserTravelLocations";



const Dashboard: FC = () => {
    const [selectedPersonId, setSelectedPersonId] = useState<string>('');
    const { data: locations, isLoading, isError } = useGetUserTravelLocations(selectedPersonId, "2024");
    const {data: delegateUsers, isLoading: isDelegatesLoading, isError: isDelegatesError} = useGetUserDelegatesQuery();
    useEffect(()=> {
        if(!selectedPersonId && delegateUsers) {
            setSelectedPersonId(delegateUsers[0].personId)
        }
    },[selectedPersonId, delegateUsers])
    return(
        <Container className={styles.dashboardContainer}>
            <Card className={classNames(styles.card)}>
                <Typography variant={"body1"}>The dashboard below provides a summary of year-to-date trips.<br/><br/>Trip
                    data is displayed on one of two tables: the US Domestic Trips table shows data for working days in the 50 US States plus Washington, D.C., and the International and US Territories Trips table shows data for working days in other countries and US territories.<br/><br/></Typography>
                <Typography variant={"h4"}>The data for the table columns:</Typography>
                <Typography className={styles.dashboardHelpText}><b>Location:</b> The location where working days have been spent<br/></Typography>
                <Typography className={styles.dashboardHelpText}><b>Unvalidated working days:</b> The sum of working days for that region that have not yet
                    been validated in the current validation month. (i.e. The Staff and/or Delegate has not yet
                    validated the trip and the information has not yet been sent to Deloitte for processing. Staff will
                    have until the 21st of the month to validate this trip or the information for this trip will
                    automatically be assumed correct.)<br/></Typography>
                <Typography className={styles.dashboardHelpText}><b>Validated working days:</b> The sum of working days for that region that have been
                    validated in the current validation month. (i.e. The Staff and/or Delegate has validated the trip,
                    but the information has not yet been sent to Deloitte for processing. Staff still have the ability
                    to update this trip information through the 21st of the month.)<br/></Typography>
                <Typography className={styles.dashboardHelpText}><b>Processed working days:</b> The sum of working days for that region that have been
                    validated in a prior month.<br/></Typography>
                <Typography className={styles.dashboardHelpText}><b>Total working days:</b> The sum of processed, validated, and unvalidated working
                    days.<br/></Typography>
            </Card>
            {!isDelegatesLoading && delegateUsers && delegateUsers.length > 1 && <Card className={styles.card}>
                 <DelegateSelect
                    instructionsText={("Please use the drop-down menu to view the dashboard per user:")}
                    delegateUsers={delegateUsers}
                    selectedUser={selectedPersonId}
                    changeSelectedUser={setSelectedPersonId}
                    />
            </Card>}
            <LocationsTable type={"domestic"} data={locations?.filter(i=> i.country==='United States') || []} isLoading={isLoading} isError={isError}/>
            {locations?.some(i=>i.country!=='United States') &&
            <LocationsTable type={"international"} data={locations?.filter(i=> i.country!=='United States') || []} isLoading={isLoading} isError={isError}/>}
        </Container>
    )

}
export default Dashboard;