import { TripDay } from "@cfacorp/business-travel-api-client";
import TripDateRange from "../../interfaces/TripDateRange";
import Trip from "../../interfaces/Trip";

const daysOfWeek : string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const date = new Date();

const getCurrentDateYYYYMMDD = (optionalDate?: Date): string => {
    return formattedDate('YYYYMMDD', optionalDate);
}

const getCurrentDateMMDDYYYY = (optionalDate?: Date): string => {
    return formattedDate('MMDDYYYY', optionalDate);
}

const formattedDate = (format: string, optionalDate?: Date): string => {
    let day, month, year;
    if(optionalDate === undefined) {
        day = String(date.getDate()).padStart(2, '0');
        month = String(date.getMonth()+ 1).padStart(2, '0')
        year = date.getFullYear();
    } else {
        day = String(optionalDate.getDate()).padStart(2, '0');
        month = String(optionalDate.getMonth()+ 1).padStart(2, '0')
        year = optionalDate.getFullYear();
    }
    switch (format) {
        case 'YYYYMMDD': {
            return year + '-' + month + '-' + day;
        }
        case 'MMDDYYYY': {
            return month + '-' + day + '-' + year;
        }
        default: {
            throw Error(`Date format: ${format} not supported`);
        }
    }
}

const lastDayOfMonth = (date: Date): number => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
}

const buildTripCreationDateRange = (trip: Trip[]): TripDateRange => {
    return {...determineEligibleTripCreationDateRange(new Date()), ...determineExcludedTripDates(trip)};
}

/** If the current month is Oct 2021, then a user can add a trip that happened between Nov 2020 – Sept 2021
 If the current month is Nov 2021, then a user can add a trip that happened between Nov 2020 – Oct 2021
 If the current month is Dec 2021, then a user can add a trip that happened between Nov 2021 – Nov 2021
 (10/31 is the cutoff, so they can only enter in trips from Nov)
 If the current month is Jan 2022, then a user can add a trip that happened between Nov 2021 – Dec 2021 */
const determineEligibleTripCreationDateRange = (optionalDate?: Date): any => {
    let endDate = optionalDate !== undefined ? optionalDate : date;
    endDate.setMonth(endDate.getMonth()-1);
    let startDate;
    const endDateMonth = endDate.getMonth();
    if (endDateMonth === 10 || endDateMonth === 11) {
        startDate = new Date(endDate.getFullYear(), 10);
    } else {
        startDate = new Date(endDate.getFullYear()-1, 10)
    }
    endDate.setDate(lastDayOfMonth(endDate));
    return {startDate, endDate}
}

const determineExcludedTripDates = (trip: Trip[]): any => {
    const exclusionDateRanges: Date[] = [];
    const exclusionDateRangesSearchArray: string[] = [];
    trip?.forEach(tripDayBlock => {
        tripDayBlock?.tripDays.forEach(tripDay => {
            let dateArray = tripDay.date.substring(0,10).split('-')
            exclusionDateRanges.push(new Date(dateArray[1] + '-' + dateArray[2] + '-' + dateArray[0]));
            exclusionDateRangesSearchArray.push(dateArray[0] + '-' + dateArray[1] + '-' + dateArray[2]);
        })
    });
    return {exclusionDateRanges, exclusionDateRangesSearchArray};
}

const verifyTripData = (tripDays: TripDay[]): boolean => {
    return tripDays.every(day => {
        // checks if dayType is not null and if Non Work it has a reason
        return ((day.dayType === 'Non Work' && day.dayReason) || day.dayType === 'Work')
            && day.country && day.state; // check if country and state arent null
    });
}

const createInitialTripDay = (date: null | Date): TripDay => {
    if (date === null) {
       throw new Error('passed in date cannot be null');
    }
    
    return {
        tripId: '', firstName: '', lastName: '', personId: '',
        date: getCurrentDateYYYYMMDD(date),
        day: daysOfWeek[date.getDay()].toUpperCase(),
        dayType: 'Work', dayReason: '',
        countryCode: 'US', country: 'United States', stateCode: '', state: '', verified: false
    }
}



export {
    verifyTripData,
    getCurrentDateYYYYMMDD,
    getCurrentDateMMDDYYYY,
    buildTripCreationDateRange,
    determineEligibleTripCreationDateRange,
    determineExcludedTripDates,
    createInitialTripDay,
    daysOfWeek
}

