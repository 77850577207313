import { TripDay, TripDays } from "@cfacorp/business-travel-api-client";
import { buildTripList } from "../../components/Landing/LandingUtil";
import Trip from "../../interfaces/Trip";
import { businessTravelApi, businessTravelService } from "./businessTravelApi";

const tripsApi = businessTravelApi.enhanceEndpoints({addTagTypes: ["Trips"]}).injectEndpoints({
    endpoints: (builder) => ({
        getTripDays: builder.query<Trip[], void>({
            query: () => ({func: businessTravelService.getTripDays, arg: {status: undefined}}),
            transformResponse: (response: TripDays, _meta: any) => (buildTripList(response.tripDays || [])),
            providesTags: ["Trips"]
        }),
        getLastUpdated: builder.query<string, void>({
            query: () => ({func: businessTravelService.getLastUpdatedTime}),
            transformResponse: (response: string, _meta: any) => (response.split(' ')[0])
        }),
        postTripDays: builder.mutation<void, {tripDays: TripDay[], createManualTrip?: boolean}>({
            query: ({tripDays, createManualTrip = false}) => ({
                func: businessTravelService.updateTripDays, 
                arg: {
                    requestBody: {tripDays: tripDays},
                    createManualTrip
                }
            }),
            invalidatesTags: ["Trips"]
        })
    })
})

export const { useGetTripDaysQuery, useGetLastUpdatedQuery, usePostTripDaysMutation } = tripsApi;