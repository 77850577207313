import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import I8ln from "../../../i18n/utils";
import {
  TabItem,
  TabPanel,
  Tabs
} from "cfa-react-components/dist/cjs";
import "./Admin.scss";
import { isAdmin } from "../../utils/helpers/AuthHelper";
import SftpTab from "./SftpTab/SftpTab";
import BannerTab from "./BannerTab/BannerTab";

const Admin: React.FC = (): JSX.Element => {
  const history = useHistory();
  const [selectedTabItemKey, setSelectedTabItemKey] = useState<string>("notif");

  useEffect(() => {
    // nav to home if not admin
    if (!isAdmin()) {
      history.push("/");
    }
  }, [history]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      className={"admin-container"}
    >

      <Tabs
      activeItemKey={selectedTabItemKey}
      onChange={(itemIndex) => setSelectedTabItemKey(itemIndex)}
      centered
      className="admin-tabs"
      >
        <TabItem itemKey="notif">{I8ln.t("APP_BANNERS_TAB")}</TabItem>
        <TabItem itemKey="sftp">{I8ln.t("TRIP_IMPORT_TAB")}</TabItem>
      </Tabs>
      <TabPanel itemKey="notif"activeItemKey={selectedTabItemKey}> <BannerTab/> </TabPanel>
      <TabPanel itemKey="sftp" activeItemKey={selectedTabItemKey}> <SftpTab/> </TabPanel>

      
    </Grid>
  );
};

export default Admin;
