import { Grid } from "@material-ui/core";
import DelegateUser from "../../interfaces/DelegateUser";
import IDelegateSelect from "../../interfaces/IDelegateSelect";
import "./DelegateSelect.scss"
import {Typography} from "cfa-react-components";
import React from "react";

const DelegateSelect: React.FC<IDelegateSelect>= ({instructionsText, defaultSelectText, delegateUsers, selectedUser, changeSelectedUser}: IDelegateSelect) => {

    return (
          <Grid container className={"textPadding"}>
            {instructionsText && 
              <Grid item xs={12} className={"textPadding delegatorSelectionGrid"}>
                <Typography variant={"h4"}>
                  {instructionsText}
                </Typography>
              </Grid>
            }
            <Grid item xs={12} className="delegatorSelectionGrid">
              <select
                name={"delegateSelect"}
                className={"delegateSelect"}
                value={selectedUser}
                onChange={(e) => changeSelectedUser(e.target.value)}
                data-test="delegate-select"
              >
                {defaultSelectText!== undefined && <option value="">{defaultSelectText}</option>}
                {delegateUsers.map((user: DelegateUser, index: number) => {
                  return (
                    <option value={user.personId} key={index}>
                      {user.firstName && user.lastName
                        ? `${user.firstName} ${user.lastName}`
                        : `${user.personId}`}
                    </option>
                  );
                })}
              </select>
            </Grid>
          </Grid>
        )
    
}

export default DelegateSelect;