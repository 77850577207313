import { Button, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import ITripDayInformation from '../../../interfaces/ITripDayInformation';
import { TripDay } from '@cfacorp/business-travel-api-client';
import dateShortener from '../../../utils/dateShortener';
import './tripDayInformation.scss';
import {TRIP_STATUS_PROCESSED} from "../../../../constants";
import I18n from '../../../../i18n/utils';

const TripDayInformation: React.FC<ITripDayInformation> = ({day, tripDate, tripStatus, country, state, updateTrip}: ITripDayInformation): JSX.Element => {
    const defaultDay: TripDay = {tripId: '', firstName: '', lastName: '', personId: '',  date: '', country: '', countryCode: '', state: '', stateCode: '', dayType: 'Work', dayReason: '', verified: false};
    
    const [tripDay, setTripDay] = useState<TripDay>(defaultDay);
    const didWork = tripDay.dayType === 'Work';

    const [countryDropdown, setCountry] = useState((country != null ? country : ''));
    const [regionDropdown, setRegion] = useState((state != null ? state : ''));

    const isProcessedStatus : boolean = tripStatus === TRIP_STATUS_PROCESSED;

    const selectCountry = (val: string): void => {
        setCountry(val);
        setRegion('');
        for (let i = 0; i < CountryRegionData.length; i++) {
            if (CountryRegionData[i][0] === val) {
                setCountry(CountryRegionData[i][0]);
                setTripDay((prev) => ({...prev, country: val, countryCode: CountryRegionData[i][1], state: '', stateCode: '',}));
            }
        }
    }

    const selectRegion = (val: string): void => {
        setRegion(val);
        for (let i = 0; i < CountryRegionData.length; i++) {
            if (CountryRegionData[i][0] === countryDropdown) {
                let regionList = CountryRegionData[i][2].split('|')
                for (let j = 0; j < regionList.length; j++) {
                    let reg = regionList[j].split('~')
                    if (reg[0] === val) {
                        setTripDay((prev) => ({...prev, state: val, stateCode: reg[1], }));
                    }
                }
            }
        }
    }

    const updateDidWork = (answer: 'Work' | 'Non Work'): void => {
        setTripDay(prev =>({...prev, dayType: answer, dayReason: answer === 'Work' ? '' : (tripDay.dayReason || '')}))
    }

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const { value } = event.target; 
        setTripDay(prev => ({ ...prev, dayReason: value })); 
    };

    useEffect((): void => {
        setTripDay(day);
    }, [day]);

    useEffect(() => {
        updateTrip(tripDay);
    }, [tripDay, updateTrip]);

    return (
        <Grid container className={'dayContainer'} data-test={`trip-day-container-${tripDate}`}>
            <Grid container  item xs={6} className={'leftSideInfo'}>
                <Grid item xs={12}><span className={'tripDayDateLabel'}>{day.day ? `${day.day} - ${dateShortener(tripDate)}` : dateShortener(tripDate)}</span></Grid>
                <Grid item lg={6} xs={12} className={'dayLocation'}>
                    <CountryDropdown disabled={isProcessedStatus}
                    priorityOptions={['US', 'CA']}
                    value={countryDropdown}
                    valueType={'full'}
                    labelType={'full'}
                    classes={`countryDropdown ${(countryDropdown === '') ? 'required': ''}`}
                    onChange={(val) => selectCountry(val)} />
                </Grid>
                <Grid item lg={6} xs={12} className={'dayLocation'}>
                    <RegionDropdown disabled={isProcessedStatus}
                    country={countryDropdown}
                    value={regionDropdown}
                    countryValueType={'full'}
                    valueType={'full'}
                    labelType={'full'}
                    classes={`regionDropdown ${(regionDropdown === '') ? 'required': ''}`}
                    onChange={(val) => selectRegion(val)} />
                </Grid>
            </Grid>
            <Grid container item xs={6} className={'rightSideInfo'}>
                <Grid container item xs={12} className={'questionButtons'}>
                    <Grid item xs={11} lg={6}>
                        <Button className={'yesPadding'} disabled={isProcessedStatus} onClick={() => updateDidWork('Work')} data-test="yes-work-button">
                            <span className={`questionButton ${isProcessedStatus && didWork ? 'disable'  
                                : (didWork ? 'selected': '')}`}>Yes</span></Button>
                        <Button className={'noPadding'} disabled={isProcessedStatus} onClick={() => updateDidWork('Non Work')} data-test="no-work-button">
                            <span className={`questionButton ${isProcessedStatus && !didWork ? 'disable' 
                            : ( !didWork ? 'selected': '')}`}>No</span></Button>
                    </Grid>
                    {tripDay.dayType === null && 
                        <Grid item xs={1} lg={6} className={'requiredButton'} >*</Grid>
                    }
                </Grid>
                <Grid item xs={12}>
                    <select name='workReasons' className={`text reasonDropDown ${(tripDay.dayType === 'Non Work'  && !tripDay.dayReason) ? 'required': ''}`} disabled={tripDay.dayType === 'Work' || isProcessedStatus} value={tripDay.dayReason} onChange={(e) => handleSelectChange(e)} data-test="reason-dropdown">
                        <option value=''/>
                        <option value='conference'>{I18n.t('NON_WORK_REASON_CONFERENCE')}</option>
                        <option value='pto'>{I18n.t('NON_WORK_REASON_PTO')}</option>
                        <option value='cancelled'>{I18n.t('NON_WORK_REASON_CANCELLED')}</option>
                        <option value='rescheduled'>{I18n.t('NON_WORK_REASON_RESCHEDULED')}</option>
                        <option value='saturday'>{I18n.t('NON_WORK_REASON_WEEKEND')}</option>
                        <option value='travel'>{I18n.t('NON_WORK_REASON_TRAVEL')}</option>
                    </select>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TripDayInformation;
