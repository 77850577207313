import { BannerMessage } from "@cfacorp/business-travel-api-client";
import { businessTravelApi, businessTravelService } from "./businessTravelApi"

const adminApi = businessTravelApi.enhanceEndpoints({addTagTypes: ["Banners", "IngestDate"]}).injectEndpoints({
    endpoints: (builder) => ({
        getBannerMessages: builder.query<BannerMessage[], void>({ 
            query: () => ({func: businessTravelService.getActiveBannerMessages, arg: undefined}),
            providesTags: ["Banners"]
        }),
        updateBannerMessage: builder.mutation<void, {newMessage: boolean, message: BannerMessage}>({
            query: ({newMessage, message}) => ({
                func: businessTravelService.saveBannerMessage, 
                arg: { newMessage, requestBody: message }
            }),
            invalidatesTags: ["Banners"]
        }), 
        deleteBannerMessage: builder.mutation<void, string>({
            query: (messageId) => ({
                func: businessTravelService.deleteBannerMessage, 
                arg: { messageId }
            }),
            invalidatesTags: ["Banners"]
        }),
        initiateSFTPIngest: builder.mutation<void, void>({
            query: () => ({
                func: businessTravelService.initiateSftpIngest, 
                arg: undefined
            }),
        }),
        initiateSFTPExport: builder.mutation<void, void>({
            query: () => ({
                func: businessTravelService.initiateSftpExport, 
                arg: undefined
            }),
        }),
    })
})

export const { 
    useGetBannerMessagesQuery,
    useUpdateBannerMessageMutation,
    useDeleteBannerMessageMutation,
    useInitiateSFTPIngestMutation,
    useInitiateSFTPExportMutation
} = adminApi;