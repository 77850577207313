import Trip from '../../interfaces/Trip';
import { TripDay, Person } from '@cfacorp/business-travel-api-client';
import DelegateUser from '../../interfaces/DelegateUser';
import {getFirstName, getLastName} from "../../utils/helpers/PersonHelper";

const sortTrips = (unsortedTrips: Trip[], direction: 'ASC' | 'DESC'): Trip[] => {
    let sortedTrips: Trip[] = [];

    if (direction === 'ASC') {
        sortedTrips = unsortedTrips.sort((a: Trip, b: Trip) => new Date(a.tripDays[0].date).getTime() - new Date(b.tripDays[0].date).getTime());
    } else if (direction === 'DESC') {
        sortedTrips = unsortedTrips.sort((a: Trip, b: Trip) => new Date(b.tripDays[0].date).getTime() - new Date(a.tripDays[0].date).getTime());
    }

    return sortedTrips;
}

const filterTrips = (trips: Trip[], tripStatus: string, personId: string,): Trip[] => {
    let filteredTrips: Trip[];

    if (personId) {
        filteredTrips = trips.filter((trip: Trip) => trip.tripStatus === tripStatus && trip.tripDays[0].personId === personId);
    } else {
        filteredTrips = trips.filter((trip: Trip) => trip.tripStatus === tripStatus);
    }
    return filteredTrips;
}

const buildTripList = (days: TripDay[]): Trip[] => {
    const uniqueIds: string[] = [...new Set(days.map(day => day.tripId || ""))];
    let trips: Trip[] = [];

    uniqueIds.forEach((id: string) => {
        const matchingTrips: TripDay[] = days.filter(day => day.tripId === id);
        if (matchingTrips.length) {
            let trip: Trip = {
                tripId: id,
                tripDays: matchingTrips,
                tripStatus: matchingTrips[0]?.tripStatus
            }
            trips.push(trip);
        }
    });

    return trips;
}

const formatDelegateUsers = (people: Person[]): DelegateUser[] => {
    return people.map((person) => {
        let user: DelegateUser = {
            cfaId: person.cfaId || '',
            personId: person.personId || '',
            firstName: getFirstName(person),
            lastName: getLastName(person)
        }
        return user;
    });
}

const separateByTaxYear = (trips: Trip[])  => {
    var taxYears: any = {}
    trips.forEach((trip: Trip) => {
        const tripDate = new Date(`${trip.tripDays[0].date} 00:00:00`);
        const taxYear = (tripDate.getMonth() <= 9 ? tripDate.getFullYear() : tripDate.getFullYear() + 1).toString();
        taxYears[taxYear] = [...(taxYears[taxYear]||[]), trip]
    })
    return taxYears
}

const getUserIds = (tripDays: TripDay[]): string[] => {
    return [...new Set(tripDays.map(day => day.personId!))];
}

export {
    sortTrips,
    filterTrips,
    buildTripList,
    getUserIds,
    separateByTaxYear,
    formatDelegateUsers
}
