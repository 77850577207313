import React, {useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid, InputLabel, MenuItem, Select
} from "@material-ui/core";
import I18n from "../../../../../i18n/utils";
import ICreateActionDialog from "../../../../interfaces/ICreateActionDialog";

const CreateActionDialog : React.FC<ICreateActionDialog> = (iCreateActionDialog: ICreateActionDialog): JSX.Element => {
    const [open, setOpen] = useState(true);
    const [selectedCreationReasonOption, setSelectedCreationReasonOption] = useState('');
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

    const handleDisagreeClose = () => {
        iCreateActionDialog.refreshView();
        setOpen(false);
    }

    const handleAgreeClose = () => {
        iCreateActionDialog.submitCreatedTrips(selectedCreationReasonOption);
        setOpen(false);
    }

    const handleOnClose = () => {
        iCreateActionDialog.refreshView();
        setOpen(false);
    }

    const handleSelectedReasonOption = (event: any) => {
        setSelectedCreationReasonOption(event);
        setIsSubmitButtonDisabled(false);
    };

    return (
      <Grid container justify={"center"} className={"saveButtonContainer"}>
        <Dialog
          open={open}
          onClose={handleOnClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className={"dialogTitle"} id="alert-dialog-title" data-test="create-modal-name">
            {iCreateActionDialog.forPerson
              ? I18n.t("TRIP_CREATION_MODAL_NAME", {
                  name: iCreateActionDialog.forPerson,
                })
              : I18n.t("TRIP_CREATION_MODAL_NO_NAME")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {I18n.t("REASON_SELECT")}
            </DialogContentText>
            <form className={"form"} noValidate>
              <FormControl>
                <InputLabel>Reasons</InputLabel>
                <Select
                  className={"selectMenu"}
                  defaultValue={""}
                  autoFocus
                  value={selectedCreationReasonOption}
                  onChange={(event) =>
                    handleSelectedReasonOption(event.target.value)
                  }
                >
                  <MenuItem value="Personal Car Trip">
                    {I18n.t("REASON_PERSONAL_CAR")}
                  </MenuItem>
                  <MenuItem value="Voluntary Travel">
                    {I18n.t("REASON_VOLUNTARY_TRAVEL")}
                  </MenuItem>
                  <MenuItem value="Voluntary Relocation">
                    {I18n.t("REASON_VOLUNTARY_RELOCATION")}
                  </MenuItem>
                  <MenuItem value="Forgot to use Direct Travel">
                    {I18n.t("REASON_FORGOT_DT")}
                  </MenuItem>
                </Select>
              </FormControl>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDisagreeClose} className="acceptButton">
              {I18n.t("APP_DAY_LIST_CANCEL_BUTTON_LABEL")}
            </Button>
            <Button
              className="acceptButton"
              disabled={isSubmitButtonDisabled}
              onClick={handleAgreeClose}
              autoFocus
              data-test="create-submit"
            >
              {I18n.t("APP_DAY_LIST_SUBMIT_BUTTON_LABEL")}
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
}

export default CreateActionDialog;
