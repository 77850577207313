import axios, { AxiosResponse } from "axios";
import { getOktaAccessToken } from "./LocalStorage";


let axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BTVT_BASE_URL
  });

const getConfig = async () => {
    const accessToken: string = getOktaAccessToken();
    return {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "cache-control": "no-cache",
        Authorization: `Bearer ${accessToken}`,
      },
    };
  };

export const API_GET = async <T>(
    path: string
  ): Promise<AxiosResponse<T>> => {
    return await axiosInstance.get<T>(path, await getConfig());
  };