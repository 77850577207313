import { StylesProvider } from '@material-ui/core';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import React, {useState} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Landing from './app/components/Landing/Landing';
import { buildOktaConfig } from './app/utils/buildOktaConfig';
import Delegate from "./app/components/Delegate/Delegate";
import TripCreate from "./app/components/Trip/TripCreate/TripCreate";
import "cfa-react-components/dist/styles.min.css";
import Admin from './app/components/Admin/Admin';
import TopNavBar from './app/components/TopNavBar/TopNavBar';
import SideMenu from './app/components/SideMenu/SideMenu';
import Dashboard from './app/components/Dashboard/Dashboard';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const App: React.FC = (): JSX.Element => {
  const oktaConfig = buildOktaConfig();

  const [openMenu, setOpenMenu] = useState<boolean>(false);

  return (
    <QueryClientProvider client={queryClient}>
      <StylesProvider injectFirst>
        <Router>
          <Security {...oktaConfig.oidc}>
            
            <SideMenu open={openMenu} setOpen={setOpenMenu} />
            <TopNavBar openMenu={setOpenMenu} />

            <Switch>
              <Route path="/implicit/callback" component={LoginCallback} />
              <SecureRoute path='/' exact component={Landing} />
              <SecureRoute path="/delegate" component={Delegate}/>
              <SecureRoute path='/trip/create' component={TripCreate}/>
              <SecureRoute path='/dashboard' component={Dashboard}/>
              <SecureRoute path='/admin' component={Admin}/>
            </Switch>
          </Security>
        </Router>
      </StylesProvider>
    </QueryClientProvider>
  );
}

export default App;
