import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
} from "@material-ui/core";
import { createTheme } from '@material-ui/core/styles';
import {
  Button,
  Icon,
  IconButton,
  Typography,
  Tag

} from "cfa-react-components/dist/cjs";
import React, { useState } from "react";
import I18n from "../../../../i18n/utils";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import "./BannerTab.scss";
import {
  useGetBannerMessagesQuery,
} from "../../../redux/features/adminApi";
import { BannerMessage } from "@cfacorp/business-travel-api-client";
import { retrieveAuthUserId } from "../../../utils/helpers/AuthHelper";
import { getOktaAccessToken } from "../../../utils/LocalStorage";
import ModifyBannerDialog, { NO_EXPIRATION_DATE } from "./ModifyBannerDialog/ModifyBannerDialog";


const theme = createTheme({
  typography: {
    fontFamily: "Apercu-regular",
  },
});

const BannerTab: React.FC = (): JSX.Element => {
  const defaultMessage = {message: "", severity: BannerMessage.severity.INFO ,creatorUserId: retrieveAuthUserId(getOktaAccessToken()), createdDate: new Date().toISOString(), updatedDate: undefined, expirationDate: NO_EXPIRATION_DATE}
  const [modifyMode, setModifyMode] = useState<"create" | "modify" | "delete">(
    "create"
  );
  const [modifyModalOpen, setModifyModalOpen] = useState<boolean>(false);
  const [selectedMessage, setSelectedMessage] = useState<BannerMessage>(defaultMessage);

  const {
    data: messages,
    isError: isGetMessagesError,
    isSuccess: isGetMessagesSuccess,
  } = useGetBannerMessagesQuery();

  const handleAddRow = () => {
    setSelectedMessage(defaultMessage);
    setModifyMode('create');
    setModifyModalOpen(true);
  };

  const handleModifyMessage = (message: BannerMessage) => {
    setSelectedMessage(message);
    setModifyMode('modify');
    setModifyModalOpen(true);
  }

  const handleDeleteMessage = (message: BannerMessage) => {
    setSelectedMessage(message);
    setModifyMode('delete');
    setModifyModalOpen(true);
  }

  return (
    <Grid item xs={12} className="section">
      <Grid item xs={12}>
        <Typography variant="h2">{I18n.t("APP_BANNERS_TAB")}</Typography>
        <Typography variant="body1">{I18n.t("BANNERS_SUBTITLE")}</Typography>
      </Grid>
      {isGetMessagesError && <ErrorMessage message={I18n.t("GET_BANNERS_ERROR")} />}
      {isGetMessagesSuccess && (
        <div className="data-grid">
          <ThemeProvider theme={theme}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell key="severity">Severity</TableCell>
                    <TableCell key="message">Message</TableCell>
                    <TableCell key="creator">Created By</TableCell>
                    <TableCell key="expiration">Expiration Date</TableCell>
                    <TableCell key="updatedDate">Last Updated</TableCell>
                    <TableCell key="modify"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {messages?.map((message) => (
                    <TableRow key={message.messageId}>
                      <TableCell key="severity">
                        <Tag
                          color={
                            message.severity === BannerMessage.severity.WARNING
                              ? "tan"
                              : message.severity ===
                                BannerMessage.severity.ERROR
                              ? "orange"
                              : undefined
                          }
                          label={message.severity}
                          variant="filled"
                        />
                      </TableCell>
                      <TableCell key="message">{message.message}</TableCell>
                      <TableCell key="creator">
                        {message.creatorUserId}
                      </TableCell>
                      <TableCell key="expiration">
                        {message.expirationDate !== NO_EXPIRATION_DATE && (new Date(message.expirationDate)).toLocaleDateString()}
                      </TableCell>
                      <TableCell key="updatedDate">
                        {(new Date(message.updatedDate || message.createdDate || '')).toLocaleString()}
                      </TableCell>
                      <TableCell key="modify">
                      <div>
                        <IconButton
                          aria-label="Edit Banner"
                          color="primary"
                          href=""
                          onClick={() => {
                            handleModifyMessage(message);
                          }}
                          size="sm"
                        >
                          <Icon icon="pencil" />
                        </IconButton>
                        <IconButton
                          aria-label="Remove Banner"
                          color="primary"
                          href=""
                          onClick={() => {
                            handleDeleteMessage(message);
                          }}
                          size="sm"
                        >
                          <Icon icon="trash" />
                        </IconButton>
                      </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </ThemeProvider>
          <div className="bottom-text">
            <Button size="sm" className="add-row" onClick={handleAddRow}>
              <Icon icon="circle-plus" />
              {I18n.t("NEW_BANNER_BUTTON")}
            </Button>
          </div>
        </div>
      )}
    <ModifyBannerDialog open={modifyModalOpen} setOpen={setModifyModalOpen} mode={modifyMode} message={selectedMessage} setMessage={setSelectedMessage}/>
      
    </Grid>
  );
};


export default BannerTab;
