import { Button, Grid } from "@material-ui/core";
import React from "react";
import { ITaxYearList } from "../../interfaces/ITaxYearList";
import I18n from "../../../i18n/utils";

const TaxYearList: React.FC<ITaxYearList> = ({ years, setTaxYear}: ITaxYearList): JSX.Element => {
  
  return (
    <>
      {years.sort((a,b) => parseInt(b)-parseInt(a)).map((year) => (
        <Button
          className={"tripButton"}
          onClick={() => setTaxYear(year)}
          key={year}
          data-test={`tax-year-${year}`}
        >
          <Grid container>
            <Grid item xs={12}>
              {I18n.t("TAX_YEAR", {year})}
            </Grid>
            <Grid item xs={12}>
              {I18n.t("TAX_RANGE", {firstYear: parseInt(year)-1, secondYear: year})}
            </Grid>
          </Grid>
        </Button>
      ))}
    </>
  );
  

};
export default TaxYearList;
