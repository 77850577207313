import { createApi } from '@reduxjs/toolkit/query/react'
import { BusinessTravelApiClient, Person } from "@cfacorp/business-travel-api-client"
import { getOktaAccessToken } from '../../utils/LocalStorage'
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';


const client = new BusinessTravelApiClient({
    BASE: process.env.REACT_APP_BTVT_BASE_URL
});
export const businessTravelService = client.businessTravel;

const businessTravelBaseQuery = (args: { func: Function, arg?: any; }, api: BaseQueryApi, extra: any) => {
    const {func, arg} = args
    client.request.config.HEADERS = {
        'Authorization' : `Bearer ${getOktaAccessToken()}`
    }

    return func.call(client.businessTravel, arg).then((res: any) => ({data: res})).catch((error: Error) => ({error: error.message}))
}


export const businessTravelApi = createApi({
    reducerPath: 'businessTravelApi',
    baseQuery: businessTravelBaseQuery,
    endpoints: (builder) => ({
        getPeople: builder.query<Person[], void>({
            query: () => ({func: businessTravelService.getPeople})
        })
    })
})

export const {useGetPeopleQuery} = businessTravelApi;