import { Grid } from "@material-ui/core";
import { Button, Typography } from "cfa-react-components/dist/cjs";
import React, { useState } from "react";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import { useInitiateSFTPIngestMutation, useInitiateSFTPExportMutation } from "../../../redux/features/adminApi";
import I18n from "../../../../i18n/utils";

const SftpTab: React.FC = (): JSX.Element => {
  const [initiateImport] = useInitiateSFTPIngestMutation();
  const [initiateExport] = useInitiateSFTPExportMutation();
  const [ingestLoad, setIngestLoad] = useState<boolean>(false);
  const [exportLoad, setExportLoad] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>();

  const triggerIngest = () => {
    setIngestLoad(true);

    initiateImport()
    .unwrap()
      .then(() => {
        if (errorMessage === I18n.t("INITIATE_REFRESH_ERROR"))
          setErrorMessage(undefined);
      })
      .catch(() => {
        setErrorMessage(I18n.t("INITIATE_REFRESH_ERROR"));
      })
      .finally(() => setIngestLoad(false));
  };

  const triggerExport = () => {
    setExportLoad(true);

    initiateExport()
    .unwrap()
      .then(() => {
        if (errorMessage === I18n.t("INITIATE_EXPORT_ERROR"))
          setErrorMessage(undefined);
      })
      .catch(() => {
        setErrorMessage(I18n.t("INITIATE_EXPORT_ERROR"));
      })
      .finally(() => setExportLoad(false));
  };

  return (
    <Grid item xs={12} className="section">
      {errorMessage && <ErrorMessage message={errorMessage} />}
      <Grid item xs={12} className="section">
        <Typography variant="h2">{I18n.t('SFTP_MANUAL_TITLE')}</Typography>
        <Typography variant="body1">
          {I18n.t('SFTP_MANUAL_SUBTITLE')}
        </Typography>

        <Button
          className="sftp-button"
          onClick={triggerIngest}
          loading={ingestLoad}
        >
          {I18n.t('IMPORT_BUTTON')}
        </Button>
        <Button className="sftp-button" onClick={triggerExport} loading={exportLoad}>
          {I18n.t('EXPORT_BUTTON')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default SftpTab;