import { Grid } from '@material-ui/core';
import React from 'react';
import IErrorMessage from '../../interfaces/IErrorMessage';
import './errorMessage.scss';

const ErrorMessage: React.FC<IErrorMessage> = ({message, cssClass}: IErrorMessage): JSX.Element => {
    return (
        <Grid item xs={12}>
            <h1 data-test="error-message" className={cssClass ? cssClass : 'default'}>{message}</h1>
        </Grid>
    )
}

export default ErrorMessage;