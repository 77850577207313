const dateShortener = (dateString: string): string => {
    let shortenDate: string = '';

    if (!isNaN(Date.parse(dateString)) && dateString.includes('-'))
    {
        const dateParts: string[] = dateString.split('-')
        
        if (dateParts.length === 3) {
            shortenDate = `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`
        }
    }

    return shortenDate;
}

export default dateShortener;