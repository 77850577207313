import {Grid} from '@material-ui/core';
import DehazeIcon from '@material-ui/icons/Dehaze';
import { useOktaAuth } from '@okta/okta-react';
import React from 'react';
import {useHistory} from 'react-router-dom';
import I8ln from '../../../i18n/utils';
import logo from '../../assets/icons/cfa.png';
import ITopNavBar from '../../interfaces/ITopNavBar';
import './topNavBar.scss';
import Toaster from './Toaster/Toaster';
import MasqueradeButton from './MasqueradeButton/MasqueradeButton';

const TopNavBar: React.FC<ITopNavBar> = ({openMenu}: ITopNavBar): JSX.Element => {
    const history = useHistory();

    const {authState} = useOktaAuth();

    const navigateToHome = () => {
        if(history.location.pathname === '/'){
            window.location.reload();
        }
        else {
            history.push('/')
        }
    };


    return ( 
        <>
        <Grid container className={'topNavBar'}>
            <Grid item xs={2} sm={1}>
                <img src={logo} alt={I8ln.t('APP_LOGO_ALT_TEXT')} className={'companyLogo'} onClick={() => navigateToHome()}/>
            </Grid>
            <Grid item xs={true}>
                <span className={'topNavBarAppName'} onClick={() => navigateToHome()}>{I8ln.t('APP_NAME')}</span>
            </Grid>
            <Grid item xs={3} sm={4} className={'topNavBarEnd'}>
                {authState.isAuthenticated && <MasqueradeButton />}
                <Grid item>
                    <DehazeIcon
                        className={'icon'}
                        onClick={() => openMenu(true)}
                        data-test="hamburger"
                    />
                </Grid>
            </Grid>            
        </Grid>
       {authState.isAuthenticated && <Toaster />}
      </>
    )
}

export default TopNavBar
