import {Button, Grid} from '@material-ui/core';
import cloneDeep from 'lodash.clonedeep';
import React, {useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import I8ln from '../../../../i18n/utils';
import ITripDaysList from '../../../interfaces/ITripDaysList';
import { TripDay } from '@cfacorp/business-travel-api-client';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import TripDayInformation from '../TripDayInformation/TripDayInformation';
import './tripDaysList.scss';
import {TRIP_STATUS_PROCESSED} from "../../../../constants";
import {
    createInitialTripDay,
    daysOfWeek,
    getCurrentDateYYYYMMDD,
    verifyTripData
} from "../../../utils/helpers/TripHelper";
import MouseOverPopover from "../TripCreate/InformationPopover/InformationPopover";
import I18n from '../../../../i18n/utils';

const TripDaysList: React.FC<ITripDaysList> = ({tripDays, tripStatus, tripDateRange, onClick, onSubmit, isCreateManualTrips}: ITripDaysList): JSX.Element => {
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [tripListDays, setTripListDays] = useState<TripDay[]>([]);
    const isProcessedStatus : boolean = tripStatus === TRIP_STATUS_PROCESSED;
    const [isInitialDatesSet, setIsInitialDatesSet] = useState<boolean>(false);
    const [pickerStartDate, setPickerStartDate] = useState<Date | null>(null);
    const [pickerEndDate, setPickerEndDate] = useState<Date | null>(null);

    const createTripStartDateMinRange = tripDateRange.startDate;
    const createTripEndDateMinRange = tripDateRange.endDate;

    const [createTripStepInstructions, setCreateTripStepInstructions] = useState<string>(I8ln.t('APP_TRIP_CREATION_STEP_TWO'));
    const [renderInformationPopover, setRenderInformationPopover] = useState<boolean>(true);

    const updateDayInformation = (tripDay: TripDay): void => {
        let days: TripDay[] = tripListDays;
        const id = days.findIndex(day => day.date === tripDay.date);
        days[id] = tripDay;
        setTripListDays(days);
    }

    const shortenTrip = (amount: number, end: boolean): void => {
        for (let i = 0; i < amount; i++) {
            end ? tripListDays.splice(-1,1) : tripListDays.splice(0, 1);
        }
    }

    const extendTrip = (amount: number, end: boolean): void => {
        for (let i = 0; i < Math.floor(amount); i++) {
            let oldDay = end ? tripListDays[tripListDays.length - 1] : tripListDays[0]
            // Can only be undefined if a user is creating a new trip manually and
            // extends the start date past the end date and then corrects the start
            // date to be before the end date.
            if (oldDay === undefined) {
               oldDay = createInitialTripDay(pickerStartDate);
            }

            let newDay = JSON.parse(JSON.stringify(oldDay));
            newDay.notes = '';

            let oldDate = new Date(newDay.date.replace(/-/g, '/'))
            oldDate.setDate(end ? oldDate.getDate() + 1: oldDate.getDate() - 1)

            newDay.date = getCurrentDateYYYYMMDD(oldDate);
            newDay.day = daysOfWeek[new Date(oldDate).getDay()].toUpperCase() ;
            end ? tripListDays.push(newDay) : tripListDays.unshift(newDay);
        }
    }

    const handlePickerStartChange = (date: any) => {
        if (isCreateManualTrips && pickerEndDate === null) {
            setCreateTripStepInstructions(I8ln.t('APP_TRIP_CREATION_STEP_THREE'));
        }
        setPickerStartDate(date);
        if (pickerEndDate !== null && date !== null) {
            let diff = calculateDiffBetweenStartOrEndDate(date, pickerEndDate, true);
            if (diff > tripListDays.length) {
                extendTrip(diff - tripListDays.length, false);
            } else {
                shortenTrip(tripListDays.length - diff, false);
            }
        }
    }

    const handlePickerEndChange = (date: any) => {
        setPickerEndDate(date);
        if (pickerStartDate !== null && date !== null) {
            let diff = calculateDiffBetweenStartOrEndDate(date, pickerStartDate, false);
            if (isCreateManualTrips && tripListDays.length === 0) {
                tripListDays.push(createInitialTripDay(pickerStartDate));
                setRenderInformationPopover(false);
                setCreateTripStepInstructions(I8ln.t('APP_DAY_LIST_INSTRUCTIONS'));
            }
            if (diff > tripListDays.length) {
                extendTrip(diff - tripListDays.length, true);
            } else {
                shortenTrip(tripListDays.length - diff, true);
            }
        }
    }

    const calculateDiffBetweenStartOrEndDate = (date: Date, pickerDate: any, isStartDateCalc: boolean): any => {
        if (isStartDateCalc) {
            return (pickerDate.getTime() - date.getTime()) / 1000 / 60 / 60 / 24 + 1

        }
        return (date.getTime() - pickerDate.getTime()) / 1000 / 60 / 60 / 24 + 1
    }

    const areRequiredFieldsSatisfied = (): boolean => {
        if (verifyTripData(tripListDays)) {
            return true;
        } else {
            setErrorMessage('You have not filled out all trip information.')
            return false;
        }
    }

    const submitVerifiedTripDays = (): void => {
        if (areRequiredFieldsSatisfied()) {
            onSubmit(tripListDays)
                .catch(() => {
                    setErrorMessage(I8ln.t("POST_TRIPS_ERROR"));
                });
        }
    }

    const bottomPageButtonGrouping = () => {
        if (isCreateManualTrips) {
            return <Grid container className={'createButtonContainer'}>
                <Grid item xs={6}>
                    <Button data-test="create-trips-button" className={'landingButton'} onClick={() => onSubmit(tripListDays)}>{I18n.t("APP_DAY_LIST_CREATE_BUTTON_LABEL")}</Button>
                </Grid>
            </Grid>
        }
        if (isProcessedStatus) {
            return <Grid container className={'bottomButtonContainer'}>
                <Grid item xs={6}>
                    <Button className={'landingButton'} onClick={() => onClick([])} data-test="back-button">{I18n.t("APP_DAY_LIST_BACK_BUTTON_LABEL")}</Button>
                </Grid>
            </Grid>
        } else {
           return <Grid container className={'bottomButtonContainer'}>
                <Grid item xs={6}>
                    <Button data-test="cancel-trips-button" className={'landingButton'} onClick={() => onClick([])}>{I18n.t("APP_DAY_LIST_CANCEL_BUTTON_LABEL")}</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button data-test="submit-trips-button" className={'landingButton'} onClick={() => submitVerifiedTripDays()}>{I18n.t("APP_DAY_LIST_SUBMIT_BUTTON_LABEL")}</Button>
                </Grid>
            </Grid>
        }
    }

    useEffect(() => {
        if (!isInitialDatesSet) {
            if (isCreateManualTrips) {
                setPickerStartDate(null);
                setPickerEndDate(null);
                setRenderInformationPopover(true);
            } else {
                if (typeof tripDateRange.startDate === "string") {
                    setPickerStartDate(new Date(tripDateRange.startDate.replace(/-/g, '/')));
                }
                if (typeof tripDateRange.endDate === "string") {
                    setPickerEndDate(new Date(tripDateRange.endDate.replace(/-/g, '/')));
                }
            }
            setIsInitialDatesSet(true);
        }
    }, [isCreateManualTrips, isInitialDatesSet, tripDateRange, renderInformationPopover])

    useEffect(() => {
        setTripListDays(cloneDeep(tripDays))
    }, [tripDays])

    return (
        <Grid container className={'tripDaysList'}>
            {errorMessage &&
                <ErrorMessage
                    message={errorMessage}
                />
            }
            {isCreateManualTrips &&
            <Grid item xs={12} className={'dayListInstructions'}>
                <span className={'text'}>{createTripStepInstructions}</span>
                {renderInformationPopover && <MouseOverPopover/>}
            </Grid>
            }
            {!isCreateManualTrips &&
            <>
                <Grid item xs={12} className={'dayListInstructions'}>
                    <span className={'text'}>{isProcessedStatus ? I8ln.t('APP_DAY_LIST_PROCESSED_INSTRUCTIONS') :
                        I8ln.t('APP_DAY_LIST_INSTRUCTIONS')}</span>
                </Grid>
                <Grid item xs={12} className={'nameBlock'}>
                    <span className='text'>Trip for: <span className={'dateLabel'}>{tripDays[0].firstName} {tripDays[0].lastName}</span></span>
                </Grid>
            </>
            }
            <Grid item xs={12} className={'dateBlock'}>
                <Grid container item xs={6} data-test="start-pick">
                    <Grid item xs={12} className={'startDate'}><span className={'text dateLabel'}>{I8ln.t('APP_DAY_LIST_START_DATE_LABEL')}</span></Grid>
                    <Grid item xs={12} className={'startDate'}>
                        <DatePicker
                            wrapperClassName="datePicker"
                            dateFormat={'MM-dd-yyyy'}
                            maxDate={isCreateManualTrips ? new Date(createTripEndDateMinRange) : pickerEndDate}
                            minDate={isCreateManualTrips ? new Date(createTripStartDateMinRange) : null}
                            excludeDates={tripDateRange.exclusionDateRanges}
                            disabled={isProcessedStatus}
                            selected={pickerStartDate}
                            onChange={handlePickerStartChange}
                            onChangeRaw={(e) => e.preventDefault()}
                        />
                    </Grid>
                </Grid>

                {pickerStartDate !== null && <Grid container item xs={6} data-test="end-pick">
                    <Grid item xs={12} className={'endDate'}><span
                        className={'text dateLabel'}>{I8ln.t('APP_DAY_LIST_END_DATE_LABEL')}</span></Grid>
                    <Grid item xs={12} className={'endDate'}>
                        <DatePicker
                            wrapperClassName="datePicker"
                            dateFormat={'MM-dd-yyyy'}
                            minDate={pickerStartDate}
                            maxDate={isCreateManualTrips ? new Date(createTripEndDateMinRange) : null}
                            excludeDates={tripDateRange.exclusionDateRanges}
                            disabled={isProcessedStatus}
                            selected={pickerEndDate}
                            onChange={handlePickerEndChange}
                            onChangeRaw={(e) => e.preventDefault()}
                        />
                    </Grid>
                </Grid>}
            </Grid>
            { (!isCreateManualTrips || (pickerStartDate && pickerEndDate)) &&
            <Grid item xs={12} className={'daysQuestion'}>
                <span className={'text'}>{ isProcessedStatus ? "" :
                    I8ln.t('APP_DAY_LIST_VALIDATION')}</span>
            </Grid> }
            {tripListDays.map((day: TripDay) => {
                return (
                    <TripDayInformation
                        key={`${day.date.replace('/', '')}-${day.tripId}`}
                        day={day}
                        tripStatus={tripStatus}
                        tripDate={day.date}
                        country={day.country || ''}
                        state={day.state || ''}
                        updateTrip={updateDayInformation}
                    />
                )
            })}
            { !isCreateManualTrips &&
            <Grid item xs={12} style={{textAlign: 'center'}}>
                <span className={'submitText'}>{isProcessedStatus ? I8ln.t('APP_DAY_LIST_PROCESSED_EDIT_INSTRUCTIONS') :
                    I8ln.t('APP_DAY_LIST_SUBMIT_INSTRUCTIONS')}</span>
            </Grid>}
            {bottomPageButtonGrouping()}
        </Grid>
    )
}

export default TripDaysList;
