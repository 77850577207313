import React, {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import I8ln from "../../../../i18n/utils";
import TripDaysList from "../TripDaysList/TripDaysList";
import { TripDay } from "@cfacorp/business-travel-api-client";
import './tripCreate.scss';
import {
    buildTripCreationDateRange,
    verifyTripData
} from "../../../utils/helpers/TripHelper";
import {TRIP_STATUS_OPEN} from "../../../../constants";
import CreateActionDialog from "./CreateActionDialog/CreateActionDialog";
import TripDateRange from "../../../interfaces/TripDateRange";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import ErrorActionDialog from "./ErrorActionDialog/ErrorActionDialog";
import {useHistory} from "react-router-dom";
import { useOktaAuth } from '@okta/okta-react';
import {retrieveAuthUserCfaId} from "../../../utils/helpers/AuthHelper";

import { useGetTripDaysQuery, usePostTripDaysMutation } from "../../../redux/features/tripsApi";
import { useGetUserDelegatesQuery } from "../../../redux/features/delegatesApi";
import DelegateSelect from "../../DelegateSelect/DelegateSelect";
import DelegateUser from "../../../interfaces/DelegateUser";

const TripCreate: React.FC = (): JSX.Element => {
    const {data: tripList, isError: isTripsError} = useGetTripDaysQuery();
    const [submitTrip] = usePostTripDaysMutation();

    const currentAuthUser = useOktaAuth().authState;
    const history = useHistory();

    const [errorMessage, setErrorMessage] = useState<string>('');

    const [renderCreateDialog, setRenderCreateDialog] = useState<boolean>(false);
    const [renderErrorDialog, setRenderErrorDialog] = useState<boolean>(false);
    const [renderTripDayListComponent, setRenderTripDayListComponent] = useState<boolean>(false);

    const [selectedUser, setSelectedUser] = useState<DelegateUser>();
    const selectedUserName = selectedUser ? selectedUser?.firstName + ' ' + selectedUser?.lastName : undefined;

    const [tripDayList, setTripDayList] = useState<TripDay[]>([]);
    const [tripCreationDateRange, setTripCreationDateRange] = useState<TripDateRange>({startDate: '', endDate: ''});
    const [excludedTripDates, setExcludedTripDates] = useState<string[]>([]);

    const {data: delegateUsers, isSuccess: isDelegatesSuccess, isError: isDelegatesError} = useGetUserDelegatesQuery();
    
    useEffect(() => {
        if(isTripsError) setErrorMessage(I8ln.t("GET_TRIPS_ERROR"))
        else if(isDelegatesError) setErrorMessage(I8ln.t("GET_DELEGATES_ERROR"))
        else setErrorMessage("")
    }, [isTripsError, isDelegatesError])

    const onCreateButtonPress = (createdTripDays: TripDay[]): Promise<void> => {
        setTripDayList(createdTripDays);
        const excludedTripDates: string[] = [];
        createdTripDays.forEach(tripDay => {
            if (tripCreationDateRange.exclusionDateRangesSearchArray?.indexOf(tripDay.date) !== -1) {
                const dateFormatArray = tripDay.date.split('-');
                excludedTripDates.push(dateFormatArray[1] + '-' + dateFormatArray[2] + '-' + dateFormatArray[0]);
            }
        });
        if(excludedTripDates.length !== 0) {
            setExcludedTripDates(excludedTripDates);
            setRenderErrorDialog(true);
        } else if(createdTripDays.length === 0) {
            setErrorMessage(I8ln.t('APP_DAY_TRIP_CREATION_START_DATE_ERROR'));
        } else if (areRequiredFieldsSatisfied(createdTripDays)) {
            setErrorMessage('');
            setRenderCreateDialog(true);
        }
        
        return Promise.resolve()
    }

    const onCloseErrorDialog = () => {
        setRenderErrorDialog(false);
    }

    const onRefreshView = () => {
        setRenderCreateDialog(false);
    }

    const handleSelectChange = (personId: string): void => {
        if(personId === '') {
            setRenderTripDayListComponent(false);
            return;
        }

        const delegate = delegateUsers?.find(delegate => delegate.personId === personId);
        if(delegate){
            setSelectedUser(delegate)
            setRenderTripDayListComponent(true);
        } else {
            setRenderTripDayListComponent(false);
        }
        
    }

    const areRequiredFieldsSatisfied = (onCreateTripDaysList: TripDay[]): boolean => {
        if (verifyTripData(onCreateTripDaysList)) {
            setErrorMessage('')
            return true;
        } else {
            setErrorMessage('You have not filled out all trip information.')
            return false;
        }
    }

    const submitTripDays = (creationReason: string) => {
        let cfaId = selectedUser?.cfaId;
        if (selectedUser?.cfaId === '' && currentAuthUser.isAuthenticated) {
            cfaId = retrieveAuthUserCfaId(currentAuthUser.accessToken);
        }
        const tripDaySubmissionList = tripDayList.map((o) =>
                ({...o, cfaId: cfaId, creationReason: creationReason}));
        submitTrip({tripDays: tripDaySubmissionList, createManualTrip: true}).unwrap()
            .then(() => {history.push('/')})
            .catch(() => {
                setErrorMessage(I8ln.t("POST_TRIPS_ERROR"));
                setRenderCreateDialog(false);
            });
    }

    //once trips are loaded, set date range
    useEffect(() => {
        if(tripList) setTripCreationDateRange(buildTripCreationDateRange(tripList.filter(o => o.tripDays[0].cfaId === selectedUser?.cfaId)));
    }, [tripList, selectedUser?.cfaId])


    useEffect(() => {
        if (isDelegatesSuccess && delegateUsers?.length === 0){
            setErrorMessage('Issue with your user\'s configuration. ' +
                            'Please submit a Service Gateway ticket.');
        }
    }, [delegateUsers, isDelegatesSuccess])

    return (<Grid container direction={'column'}>
        {errorMessage &&
            <ErrorMessage
                message={errorMessage}
            />
        }

        <Grid item xs={12} className={'instructionsText'} >
            <span>{I8ln.t('APP_TRIP_CREATION_INSTRUCTIONS')}</span>
        </Grid>

        <DelegateSelect 
            instructionsText={!renderTripDayListComponent ? I8ln.t('APP_TRIP_CREATION_STEP_ONE') : undefined} 
            defaultSelectText={""}
            delegateUsers={delegateUsers || []} 
            selectedUser={selectedUser?.personId} 
            changeSelectedUser={handleSelectChange}
        />

        <Grid className={'tripCreateTripListContainer'}>
            {renderTripDayListComponent &&
            <TripDaysList
                tripDays={tripDayList}
                tripStatus={TRIP_STATUS_OPEN}
                tripDateRange={tripCreationDateRange}
                onClick={(() => {})}
                onSubmit={onCreateButtonPress}
                isCreateManualTrips={true}
            />}

            {renderErrorDialog && <ErrorActionDialog
                excludedTrips={excludedTripDates} onCloseErrorDialog={onCloseErrorDialog} />}
            {renderCreateDialog && <CreateActionDialog forPerson={selectedUserName}
                submitCreatedTrips={submitTripDays} refreshView={onRefreshView}/>}
        </Grid>
    </Grid>);
}

export default TripCreate;
