import { CircularProgress, Grid } from '@material-ui/core';
import React  from 'react';
import ILoadingSpinner from '../../interfaces/ILoadingSpinner';
import  './loadingSpinner.scss';

const LoadingSpinner: React.FC<ILoadingSpinner> = ({loadingMessage, cssCLassName}: ILoadingSpinner): JSX.Element => {
    return (
        <Grid container className={cssCLassName ? cssCLassName : 'loadingDataMessageDefault'}>
            <Grid item container>
                <Grid item xs={12}>
                    <span>{loadingMessage}</span>
                </Grid>
                <Grid item xs={12}>
                    <CircularProgress />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default LoadingSpinner;