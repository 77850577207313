import { Button, Grid } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import I18n from "../../../i18n/utils";
import ILandingTripList from "../../interfaces/ILandingTripList";
import Trip from "../../interfaces/Trip";
import TripButton from "../Trip/TripButton/TripButton";
import "./landingTripList.scss";
import {
  TRIP_STATUS_OPEN,
  TRIP_STATUS_PROCESSED,
  TRIP_STATUS_VERIFIED,
} from "../../../constants";
import { useGetLastUpdatedQuery } from "../../redux/features/tripsApi";
import TaxYearList from "../TaxYearList/TaxYearList";
import { separateByTaxYear } from "../Landing/LandingUtil";
import DelegateSelect from "../DelegateSelect/DelegateSelect";

const LandingTripList: React.FC<ILandingTripList> = ({
  delegateUsers,
  trips,
  selectedUser,
  viewOnClick,
  selectUser,
  tripOnClick,
  tripStatus,
  taxYear,
  setTaxYear
}: ILandingTripList): JSX.Element => {
  const [tripList, setTripList] = useState<Trip[]>([]);
  const { data: lastUpdated } = useGetLastUpdatedQuery();

  // determines which statuses should be futher separated out by tax year
  const shouldSeparateByTaxYear = tripStatus === TRIP_STATUS_PROCESSED

  const taxTripList = useMemo(() => {
    return separateByTaxYear(trips)
  }, [trips])

  useEffect(() => {
    if(taxYear){
      setTripList(taxTripList[taxYear])
    } else {
      setTripList(trips)
    }
  }, [taxYear, trips, taxTripList])

  const changeView = (status: string) => {
    setTaxYear(undefined)
    viewOnClick(status)
  }

  const instructionText = (): string => {
    if(shouldSeparateByTaxYear && !taxYear) return I18n.t("APP_TAX_YEAR_INSTRUCTIONS");

    switch (tripStatus) {
      case TRIP_STATUS_OPEN:
        return I18n.t("APP_VALIDATION_INSTRUCTIONS");
      case TRIP_STATUS_VERIFIED:
        return I18n.t("APP_VALIDATED_INSTRUCTIONS");
      case TRIP_STATUS_PROCESSED:
        return I18n.t("APP_PROCESSED_INSTRUCTIONS");
      default:
        return "";
    }
  };

  const validationButtonGrouping = () => {
    if (tripStatus === TRIP_STATUS_OPEN) {
      return (
        <Grid item xs={6} className={"validationButtonGrouping"}>
          <Button
            className={"landingButton"}
            onClick={() => changeView(TRIP_STATUS_VERIFIED)}
            data-test="validated-trips-button"
          >
            <span>{I18n.t("APP_VALIDATED_TRIPS_BUTTON_TEXT")}</span>
          </Button>
          <Button
            className={"landingButton"}
            onClick={() => changeView(TRIP_STATUS_PROCESSED)}
            data-test="processed-trips-button"
          >
            <span>{I18n.t("APP_PROCCESSED_TRIPS_BUTTON_TEXT")}</span>
          </Button>
        </Grid>
      );
    } else {
      return (
        <Grid item xs={6}>
          <Button
            className={"landingButton"}
            onClick={() => changeView(TRIP_STATUS_OPEN)}
            data-test="unvalidated-trips-button"
          >
            <span>{I18n.t("APP_NONVALIDATED_TRIPS_BUTTON_TEXT")}</span>
          </Button>
        </Grid>
      );
    }
  };


  return (
    <Grid container>
      <Grid container className={"landingFilters"}>
        {delegateUsers.length > 1 && <DelegateSelect
          instructionsText={I18n.t("APP_DELEGATE_INSTRUCTIONS")}
          defaultSelectText={I18n.t("APP_VIEW_ALL_TRIPS")}
          delegateUsers={delegateUsers}
          selectedUser={selectedUser}
          changeSelectedUser={selectUser}
        />}

        <Grid item xs={12} className={"textPadding"}>
          <span className={"text"}>
            {tripStatus !== TRIP_STATUS_OPEN
              ? I18n.t("APP_SHOW_NONVALIDATED_TRIPS")
              : I18n.t("APP_SHOW_VALIDATED_TRIPS")}
          </span>
        </Grid>
        {validationButtonGrouping()}
      </Grid>

      <Grid container className={"landingTripList"}>
        {!trips.length ? (
          <Grid item xs={12} className={"landingInstructions"}>
            <span className={"text"}>{I18n.t("APP_NO_TRIPS")}</span>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} className={"landingInstructions"}>
              <span className={"text"}>{instructionText() + (taxYear ?  I18n.t("APP_FOR_TAX_YEAR", {taxYear}) : "")}</span>
              {lastUpdated !== "" && <p className="pLanding">{I18n.t("APP_DATA_LOADED", {date: lastUpdated})}</p>}
            </Grid>
            <Grid container justifyContent="center" className={"tripList"}>
              {shouldSeparateByTaxYear && !taxYear ? (
                <TaxYearList
                  years={Object.keys(taxTripList)}
                  setTaxYear={setTaxYear}
                />
              ) : (
                <>
                  {taxYear && (
                    <Button
                      className={"backButton"}
                      onClick={() => setTaxYear(undefined)}
                      data-test="tax-year-back-button"
                    >
                      {I18n.t("APP_BACK_TAX_LIST")}
                    </Button>
                  )}
                  {tripList.map((trip: Trip, index: number) => {
                    return (
                      <TripButton
                        key={index}
                        trip={trip}
                        selectTrip={tripOnClick}
                      />
                    );
                  })}
                </>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default LandingTripList;
