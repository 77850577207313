import { API_GET } from "../app/utils/API";
import { useQuery } from "@tanstack/react-query";
import LocationDays from "../app/interfaces/LocationDays";


function useGetUserTravelLocations(personId:String, taxYear:String) {
    const getUserTravelLocations = async () => {
        const response = await API_GET<LocationDays[]>(`/trips/table?personId=${personId}&taxYear=${taxYear}`);
        return response.data;
    };

    return useQuery({
        queryKey: ["locations", personId, taxYear],
        queryFn: getUserTravelLocations,
        enabled: !!personId
    });
}

export { useGetUserTravelLocations };
