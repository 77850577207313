import { Person } from "@cfacorp/business-travel-api-client";
import {retrieveAuthUserCfaId} from "../../utils/helpers/AuthHelper";
import { getMasqueradedUserCookie, getOktaAccessToken, removeLocalStorageValue, setLocalStorageValue } from "../../utils/LocalStorage";
import { businessTravelApi, businessTravelService } from "./businessTravelApi";

const masqueradeApi = businessTravelApi.enhanceEndpoints({addTagTypes: ["Trips", "Delegates", "Configuration", "CanMasq", "IsMasq"]}).injectEndpoints({
    endpoints: (builder) => ({
        getCanMasquerade: builder.query<boolean, void>({
            queryFn: async (_arg, _queryApi, _extraOptions, baseQuery) => ({
                 data: await baseQuery({
                    func: businessTravelService.canUserMasquerade, 
                    arg: {cfaId: retrieveAuthUserCfaId(getOktaAccessToken())
                }}).then((res: any) => ('data' in res) ? true : false)
            }),
            providesTags: ["CanMasq"]
        }),
        getMasqueradeSession: builder.query<Person | null, void>({
            query: () => ({func: businessTravelService.isMasqueraded, arg: {cfaId: retrieveAuthUserCfaId(getOktaAccessToken())}}),
            transformResponse: (isMasq: boolean, _meta) => {
                var cookie =  getMasqueradedUserCookie();
                if(isMasq && cookie) return cookie;

                // not masq anymore, removie cookie
                if(cookie) removeLocalStorageValue('masqueraded-user');
                return null;
            },
            providesTags: ["IsMasq"]
        }), 
        putMasqueradeSession: builder.mutation<void, {person: Person}>({
            query: ({person}) => ({
                func: businessTravelService.masquerade, 
                arg: {masqueradeCfaId: person.cfaId}
            }),
            invalidatesTags: ["IsMasq", "Trips", "Delegates", "Configuration"],
            onCacheEntryAdded: async ({person}, {cacheDataLoaded}) => await cacheDataLoaded.then(() => 
                setLocalStorageValue('masqueraded-user', JSON.stringify(person))
            )
        }),
        removeMasqueradeSession: builder.mutation<void, void>({
            query: () => ({func: businessTravelService.clearMasquerade, arg: {cfaId: retrieveAuthUserCfaId(getOktaAccessToken())}}),
            invalidatesTags: ["IsMasq", "Trips", "Delegates", "Configuration"],
            onCacheEntryAdded: async (_arg, {cacheDataLoaded}) => await cacheDataLoaded.then(() => 
                removeLocalStorageValue('masqueraded-user')
            )
        })
    })
})

export const { useGetCanMasqueradeQuery, useGetMasqueradeSessionQuery, usePutMasqueradeSessionMutation, useRemoveMasqueradeSessionMutation } = masqueradeApi;