import {Grid} from '@material-ui/core';
import React, {useState} from 'react';
import I8ln from '../../../../i18n/utils';
import '../topNavBar.scss';
import Masquerade from "../../Masquerade/Masquerade";
import { Person } from "@cfacorp/business-travel-api-client";
import {getFullName} from "../../../utils/helpers/PersonHelper";
import { useGetCanMasqueradeQuery, useGetMasqueradeSessionQuery, usePutMasqueradeSessionMutation, useRemoveMasqueradeSessionMutation } from '../../../redux/features/masqueradeApi';

const MasqueradeButton: React.FC = (): JSX.Element => {
    const [isMasqueradeDialogOpen, setIsMasqueradeDialogOpen] = useState<boolean>(false);
    const {data: masqueradedUser} = useGetMasqueradeSessionQuery();
    const isMasqueraded = masqueradedUser ? true : false;
    const {data: canMasquerade} = useGetCanMasqueradeQuery();
    const [putMasqueradeSession] = usePutMasqueradeSessionMutation();
    const [removeMasqueradeSession] = useRemoveMasqueradeSessionMutation();


    const closeMasquerade = (person: Person | null) => {
        if(person) {
            putMasqueradeSession({person});
        }
        setIsMasqueradeDialogOpen(false);
    }

    const renderMasquerade = () => {
        if (canMasquerade) {
            if (isMasqueraded) {
                return (
                <span title={I8ln.t('APP_MASQUERADE_DEACTIVATE')} data-test="masq-name" 
                 onClick={() => removeMasqueradeSession()}>{I8ln.t('APP_CURRENT_MASQUERADE')}
                    {getFullName(masqueradedUser || null)}</span>
                )

            } else {
                return (
                <>
                <span className={'masquerade'} title={I8ln.t('APP_MASQUERADE_ACTIVATE')}
                      onClick={() => setIsMasqueradeDialogOpen(true)}>{I8ln.t('APP_MASQUERADE')}</span>
                    <Masquerade isDialogOpen={isMasqueradeDialogOpen} closeMasquerade={closeMasquerade}/>
                </>
                )
            }
        }
    }
    return (
        <Grid item className={'masquerade'} data-test={canMasquerade ? "masq-true" : "masq-false"} >
            {renderMasquerade()}
        </Grid>
    )
}

export default MasqueradeButton;
