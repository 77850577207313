import { Button, Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import ITripButton from '../../../interfaces/ITripButton';
import dateShortener from '../../../utils/dateShortener';
import './tripButton.scss';

const TripButton: React.FC<ITripButton> = ({trip, selectTrip}: ITripButton): JSX.Element => {
    const startDate: string = dateShortener(trip.tripDays[0].date);
    const endDate: string = dateShortener(trip.tripDays[trip.tripDays.length-1].date);

    const locationSet = useMemo(() => {
        return [...(new Set(trip.tripDays.map(({state, country, countryCode}) => {
            if (state){
                return `${state}, ${countryCode}`
            } else {
                return country
            }
        })))]
    }, [trip])


    return (
        <Button className={'tripButton'} onClick={() => selectTrip(trip.tripId)} data-test="trip-button">
            <Grid container>
                <Grid item xs={12}>
                    <span>{`${trip.tripDays[0].firstName} ${trip.tripDays[0].lastName}`}</span>
                </Grid>
                
                {locationSet.map((location) =><Grid item key={location} xs={12}>{location}</Grid>)}
                
                <Grid item xs={12}>
                    <span>{`${startDate} - ${endDate}`}</span>
                </Grid>
            </Grid>
        </Button>
    )
}

export default TripButton;
