import OktaCookie from '../interfaces/OktaCookie';
import Person from "../interfaces/Person";

type LocalStorageKeys = 'okta-token-storage' | 'masqueraded-user';

const getLocalStorageValue = (key: LocalStorageKeys): string => {
    const value: string|null = localStorage.getItem(key);

    return value ? value : '';
}

const setLocalStorageValue = (key: LocalStorageKeys, value: string) => {
    localStorage.setItem(key, value);
}

const removeLocalStorageValue = (key: LocalStorageKeys) => {
    localStorage.removeItem(key);
}

const getOktaAccessToken = (): string => {
    const oktaCookie: string = getLocalStorageValue('okta-token-storage');
    let accessToken: string  = '';

    if (oktaCookie) {
        const token: OktaCookie = JSON.parse(oktaCookie);
        accessToken = token.accessToken?.value;
    }

    return accessToken;
}

const getMasqueradedUserCookie = (): Person | null => {
    const personCookie: string = getLocalStorageValue('masqueraded-user');
    if (personCookie) {
        return JSON.parse(personCookie);
    }

    return null;
}

export {
    setLocalStorageValue,
    removeLocalStorageValue,
    getOktaAccessToken,
    getMasqueradedUserCookie
}
